import React, { Component, Suspense } from "react";
import * as ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import "../css/style.css";
import "../css/purpleresponsive.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//import Header from "../Reliance/header";
//import Footer from "../Reliance/footer";
import DoctorList from "./doctor-list-elastic";
import search from "../image/media/search.png";
import filterimg from "../image/theme/filter.png";
import Pagination from "react-js-pagination";
import { apiCalling, asyncApiUrlRelianceElasticSearch,elasticSearchApicall } from "../apiService";
import { elasticLink } from "../config";
import $ from "jquery";
import doctorimg from "../image/media/doctor.jpg";
import chat from "../image/theme/chatg.png";
import booking from "../image/theme/inperson.png";
import video from "../image/theme/videog.png";
import call from "../image/theme/phoneg.png";
import { Helmet } from "react-helmet";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

const AWS = require("aws-sdk");
const elasticsearch = require("elasticsearch");
AWS.config.region = "us-west-1";

export default class DoctorListMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      doctorlist: [],
      doctorResult: [],
      hospitalResult: [],
      doctorDataCount: "",
      searchResultCnt: 0,
      searchResult: [],
      specialityResult: [],
      hospitalResult: [],
      doctorResult: [],
      sectionName: "",
      sectionId: "",
      sectionType: "",
      max: 24,
      offset: 0,
      loading: false,
      noData: false,
      // s3AccessKey: "",
      // s3SecretKey: "",
      doctorServices: [],
      serviceType: this.props.match.params.serviceType,
      randomNum: Math.floor(Math.random() * 10000000),
      favouriteDoctorsList: [],
    };
    window.sessionStorage.setItem("AppFlag", "NA");
    this.searchClick = this.searchClick.bind(this);
    this.enterSearch = this.enterSearch.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
  }

  getDataFromESSearch(hospitalId, doctorId, specialization, city, state) {
    $(".loader").show();
    $("body").css("overflow", "hidden");
    const loginRequest = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorList",
        siteId: window.sessionStorage.getItem("siteId"),
        max: this.state.max,
        offset: this.state.offset,
        userId: "",
        state: state,
        city: city,
        doctorId: doctorId,
        hospital: hospitalId,
        specializationId: specialization,
      }),
    };
    $(".loader").show();
    apiCalling(loginRequest).then((data) => {
      if (data.success === "1") {
        this.hideLoader();
        $(".loader").hide();
        $("#searchBoxDropdown").hide();
        $(".loader").hide();
        $("body").css("overflow", "auto");
        this.setState({
          doctorlist: data.doctorData,
        });
        this.setState({ doctorDataCount: data.doctorDataCount });
        $(".loader").hide();
        $("body").css("overflow", "auto");
      }
    });
  }

  hideLoader = () => {
    this.setState({ loading: false });
  };

  doctorListAPI(max, offset) {
    $("#searchBoxDropdown").hide();

    $(".loader").show();
    $("body").css("overflow", "hidden");

    const loginRequest = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorList",
        siteId: window.sessionStorage.getItem("siteId"),
        max: max,
        offset: offset,
      }),
    };
    apiCalling(loginRequest).then((data) => {
      if (data.success === "1") {
        this.setState({
          doctorlist: data.doctorData,
        });
        $(".loader").hide();
        $("body").css("overflow", "auto");
      } else {
        alert(data.errorMessage);
      }
    });
  }
  handlePageChange(pageNumber) {
    window.scrollTo(0, 0);
    let max = this.state.max;
    let offset = this.state.offset;

    if (pageNumber === "1") {
      offset = "0";
      max = "24";
    } else {
      offset = parseInt(pageNumber - 1) * 24;
      max = "24";
    }

    this.getDoctors(
      offset,
      max,
      this.state.sectionId,
      this.state.sectionType,
      this.state.sectionName
    );
    this.setState({ max: max, offset: offset });
    this.setState({ activePage: pageNumber });
  }

  profileAvailable() {
    $("#drServiceMain1").slideDown(400);
  }
  profileAvailableCancl() {
    $("#drServiceMain1").slideUp(400);
  }
  filtersearch() {
    $("#filter-search-details").slideToggle();
  }
  // awsCredentials() {
  //   const apiJson = {
  //     method: "POST",
  //     body: JSON.stringify({
  //       functionName: "imagePath",
  //       siteId: window.sessionStorage.getItem("siteId"),
  //     }),
  //   };
  //   apiCalling(apiJson).then((data) => {
  //     if (data.success === "1") {
  //       this.setState({
  //         s3AccessKey: data.configPathDetails[0].s3bucketAccessKey,
  //       });
  //       this.setState({
  //         s3SecretKey: data.configPathDetails[0].s3bucketSecretKey,
  //       });
  //     }
  //   });
  // }
  getDoctorService() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "groupService",
        siteId: window.sessionStorage.getItem("siteId"),
      }),
    };
    // console.log(">>>>>>>>>>>>>>.." + apiJson.body);
    apiCalling(apiJson).then((data) => {
      // console.log('service....data......',data)
      var service = [];
      if (data.service) {
        for (let i = 0; i < data.service.length; i++) {
          service.push(data.service[i].serviceId.toString());
        }
      } else {
        this.getDoctorService();
      }
      // }
      // data.service.forEach(function (item) {
      //   service.push(item.serviceId.toString());
      // });
      //console.log('service....getDoctorService......',service)
      if (data.success === "1") {
        this.setState({
          doctorServices: service,
        });
        this.getDoctors(0, 24);
      }
    });
  }
  componentDidMount() {
    if (folderName === null) {
      window.sessionStorage.setItem("pathname", window.location.pathname);
      window.location.href = "/";
    }
    this.getFavourites();
    // this.awsCredentials();
    this.getDoctorService();
    this.getDoctors(0, 24);
    $(document).mouseup(function (e) {
      var container = $(".searchBoxDropdown");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        container.hide();
      }
    });
    if (
      window.sessionStorage.getItem("sectionId") !== "" ||
      window.sessionStorage.getItem("sectionType") !== ""
    ) {
      this.loadData(
        window.sessionStorage.getItem("sectionId"),
        window.sessionStorage.getItem("sectionType"),
        window.sessionStorage.getItem("sectionName")
      );
    }
    window.sessionStorage.setItem("sectionId", "");
    window.sessionStorage.setItem("sectionType", "");
    window.sessionStorage.setItem("sectionName", "");
    // if (this.props.location.state !== undefined) {
    //   this.loadData(
    //     this.props.location.state.sectionId,
    //     this.props.location.state.sectionType,
    //     this.props.location.state.sectionName
    //   );
    // }

  }
  getFavourites() {
    if(window.sessionStorage.getItem("userId")!==null){
      const apiJson = {
        method: "POST",
        body: JSON.stringify({
          functionName: "myFavourites",
          userId: window.sessionStorage.getItem("userId"),
        }),
      };
      apiCalling(apiJson).then((data) => {
        if(data.success==='1'){
          for (let i = 0; i < data.doctorList.length; i++) {
            this.setState({
              favouriteDoctorsList: data.doctorList,
            });
          }
        }
      });
    }
  }
  loadData(sectionId, sectionType, sectionName) {
    // alert(sectionName)
    $("#doctorSearch").val(sectionName);
    this.enterSearch();
    window.scrollTo(0, 0);
    //$(".searchBoxDropdown").hide();
    //$("#doctorSearch").val("");

    this.setState({
      sectionName: sectionName,
      sectionId: sectionId,
      sectionType: sectionType,
    });
    // this.getDoctors(0, 24, sectionId, sectionType, sectionName);
    this.getDoctors(0, 24,sectionId,sectionType,sectionName);
  }

  async getDoctors(offset, max, sectionId, sectionType, sectionName) {
    //  alert("getDoctors:"+offset+" - "+ max+" - "+ sectionId+" - "+ sectionType+" - "+sectionName)
    $(".searchBoxDropdown").hide();

    var hospitalId = "",
      stateId = "",
      cityName = "",
      doctorIds = "",
      SpecialitiesOrMedicinBranch = "",
      spetialityId = "",
      inperson = this.state.serviceType === '1' ? "1" : "*",
      chat = this.state.serviceType === '2' ? "1" : "*",
      video = this.state.serviceType === '3' ? "1" : "*",
      audio = this.state.serviceType === '4' ? "1" : "*";

     

    if (sectionType === "HOSPITAL") {
      hospitalId = sectionId;
    } else if (sectionType === "STATE") {
      stateId = sectionId;
    } else if (sectionType === "CITY") {
      cityName = sectionName;
    } else if (sectionType === "SYMPTOM") {
      doctorIds = sectionId;
    } else if (sectionType === "COMMON NAME") {
      SpecialitiesOrMedicinBranch = sectionId;
    } else if (sectionType === "SPECIALITY") {
      spetialityId = sectionId;
    }

    //alert("inside getDoctors  ")
    // const client = new elasticsearch.Client({
    //   host: "https://search-rhealth-prod-d53qbfwui7ffwcaelbhygfdlq4.ap-south-1.es.amazonaws.com",
    //   amazonES: {
    //     region: "ap-south-1",
    //     credentials: new AWS.Credentials(
    //       this.state.s3AccessKey,
    //       this.state.s3SecretKey
    //     ),
    //   },
    // });

    //  var fields = ['site_id', 'specialization_id.keyword', 'stateid.keyword', 'city.keyword', 'medicine_branch_id.keyword', 'section_id.keyword','section_type.keyword','is_call_enabled.keyword','is_ask_enabled.keyword','is_chat_enabled.keyword','is_video_enabled.keyword','ishosonline.keyword'];

    var siteId = window.sessionStorage.getItem("siteId");
    var filters = [];
    var fields = [
      "site_id",
      "section_type.keyword",
      "hospital_id.keyword",
      "stateid.keyword",
      "city.keyword",
      "section_id.keyword",
      "specialization_id.keyword",
      "specialization_id.keyword",
      "is_call_enabled",
      "is_chat_enabled",
      "is_video_enabled",
      "ishosonline",
    ];
   
    var paramsVal = [
      siteId,
      "DOCTORBYHOSPITAL",
      hospitalId,
      stateId,
      cityName,
      doctorIds,
      SpecialitiesOrMedicinBranch,
      spetialityId,
      audio,
      chat,
      video,
      inperson,
    ];
 
    for (var k = 0; k < fields.length; k++) {
     
      if (
        paramsVal[k] !== null &&
        paramsVal[k] !== "" &&
        paramsVal[k] !== "*"
      ) {
        
        var termq = { term: {} }; 
        termq.term[fields[k]] = paramsVal[k];
        filters.push(termq);      
      }
    }
    
     
    if ($("#doctorSearch").val() !== "") {
      // console.log("1111111..........");
      const body =
      {
        "index": elasticLink(),
        "offset":offset,
        "max":max,
        "searchBody":{
          query: {
            bool: {
              must: {
                multi_match: {
                  query: $("#doctorSearch").val(),
                  type: "phrase_prefix",
                  fields: ["search_field"],
                },
              },
              filter: {
                bool: {
                  must: filters,
                },
              }, 
            },
          },
          sort: {
            _script: {
              script:
                "(doc['_id'].value + " + this.state.randomNum + ").hashCode()",
              type: "number",
              order: "asc",
            },
          },
        },
    }
      //  await client.search(
      //   {
      //   index: elasticLink(),
      //   from: offset,
      //   size: max,
      //   body: {
      //     query: {
      //       bool: {
      //         must: {
      //           multi_match: {
      //             query: $("#doctorSearch").val(),
      //             type: "phrase_prefix",
      //             fields: ["search_field"],
      //           },
      //         },
      //         filter: {
      //           bool: {
      //             must: filters,
      //           },
      //         }, 
      //       },
      //     },
      //     sort: {
      //       _script: {
      //         script:
      //           "(doc['_id'].value + " + this.state.randomNum + ").hashCode()",
      //         type: "number",
      //         order: "asc",
      //       },
      //     },
      //   },
      // }
    // );
      const response = await elasticSearchApicall(body);
      
      // console.log("body.hits.hits....111....", body.hits.hits);
      this.setState({
        doctorlist: response.result.hits.hits,
        doctorDataCount:response.result.hits.total.value,
      });
    } else {
     
      //console.log("222222222..........");
      const body = {
        "index": elasticLink(),
        "offset":offset,
        "max":max,
        "searchBody":{
          query: {
            bool: {
              filter: {
                bool: {
                  must: filters,
                },
              },
            },
          },
          sort: {
            _script: {
              script:
                "(doc['_id'].value + " + this.state.randomNum + ").hashCode()",
              type: "number",
              order: "asc",
            },
          },
        }
    }
    const response = await elasticSearchApicall(body);
      //  await client.search({
      //   index: elasticLink(),
      //   from: offset,
      //   size: max,
      //   body: {
      //     query: {
      //       bool: {
      //         filter: {
      //           bool: {
      //             must: filters,
      //           },
      //         },
      //       },
      //     },
      //     sort: {
      //       _script: {
      //         script:
      //           "(doc['_id'].value + " + this.state.randomNum + ").hashCode()",
      //         type: "number",
      //         order: "asc",
      //       },
      //     },
      //   },
      // });
      // console.log("body.hits.hits....222....", filters);
      this.setState({
        doctorlist: response.result.hits.hits,
        doctorDataCount: response.result.hits.total.value,
      });
    }
  }

  loadInitData() {
    $(".loader").show();
    $("body").css("overflow", "hidden");
    const jsonObj = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorList",
        siteId: window.sessionStorage.getItem("siteId"),
        max: this.state.max,
        offset: this.state.offset,
      }),
    };

    //console.log(jsonObj.body);
    apiCalling(jsonObj).then((data) => {
      if (data.success === "1") {
        // console.log("mmm", data);
        this.setState({
          doctorlist: data.doctorData,
        });
        this.setState({ doctorDataCount: data.doctorDataCount });
        $(".loader").hide();
        $("body").css("overflow", "auto");
      }
    });
  }

  clearSearch() {
    $("#doctorSearch").val("");
    this.setState({
      sectionName: "",
      sectionId: "",
      sectionType: "",
      max: 24,
      offset: 0,
      activePage: 1,
    });
    window.sessionStorage.setItem("sectionId", "");
    window.sessionStorage.setItem("sectionType", "");
    window.sessionStorage.setItem("sectionName", "");
    this.getDoctors(0, 24);
  }

  enterSearch() {
    //alert();
    if (
      $("#doctorSearch").val() !== "" &&
      $("#doctorSearch").val().length > 2 &&
      this.state.noData === true
    ) {
      $(".searchBoxDropdown").hide();
      this.setState({
        sectionName: $("#doctorSearch").val(),
        sectionId: "",
        sectionType: "",
      });
      this.getDoctors(0, 24);
    } else {
      $("#doctorSearch").focus();
    }
  }

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (
        $("#doctorSearch").val() !== "" &&
        $("#doctorSearch").val().length > 2 &&
        this.state.noData === true
      ) {
        $(".searchBoxDropdown").hide();
        this.setState({
          sectionName: $("#doctorSearch").val(),
          sectionId: "",
          sectionType: "",
        });
        this.getDoctors(0, 24);
      } else {
        $("#doctorSearch").focus();
      }
    }
  };

  async searchClick() {
    if ($("#doctorSearch").val().length > 2) {
      // const client = new elasticsearch.Client({
      //   host: "https://search-rhealth-prod-d53qbfwui7ffwcaelbhygfdlq4.ap-south-1.es.amazonaws.com",
      //   amazonES: {
      //     region: "ap-south-1",
      //     credentials: new AWS.Credentials(
      //       this.state.s3AccessKey,
      //       this.state.s3SecretKey
      //     ),
      //   },
      // });
      var siteId = window.sessionStorage.getItem("siteId");
      var filters = [];
      var fields = ["site_id"];
      var paramsVal = [siteId];
      for (var k = 0; k < fields.length; k++) {
        if (paramsVal[k] != null && paramsVal[k] != "" && paramsVal[k] != "*") {
          var termq = { term: {} };
          termq.term[fields[k]] = paramsVal[k];
          filters.push(termq);
        }
      }

      const jsonApiUrlRelianceElasticSearchReq = {
        method: "POST",
        body: JSON.stringify({
          city: "*",
          functionName: "commonSearch",
          max: 20,
          medicine_branch_id: "*",
          offset: "0",
          pm_site_id: "1",
          searchKeyword: $("#doctorSearch").val(),
          section_id: "*",
          section_type: "*",
          specialization_id: "*",
          stateid: "*",
        }),
      };
      // console.log("^^^^^^", jsonApiUrlRelianceElasticSearchReq.body);
      //alert(jsonLoginReq.body);
      asyncApiUrlRelianceElasticSearch(jsonApiUrlRelianceElasticSearchReq).then(
        (data) => {
          //alert(data.success)
          if (data.success === "1") {
            var specialityResult = [];
            var doctorResult = [];
            var hospitalResult = [];
            var finalResult = [];
            var result = data.result.commonSearch.result;
            // console.log("sizee=========" + result.length);
            for (var i = 0; i < result.length; i++) {
              // if(result[i]._source.section_type != 'DOCTORBYHOSPITAL' && result[i]._source.section_type != 'STATE'
              // && result[i]._source.section_type != 'CITY' && result[i]._source.section_type != 'SYMPTOM'
              // && result[i]._source.section_type != 'COMMON NAME' && result[i]._source.section_type != 'PROCEDURE'){

              if (
                result[i]._source.section_type !== "DOCTORBYHOSPITAL" &&
                result[i]._source.section_type !== "PROCEDURE" &&
                result[i]._source.section_type !== "PROCEDURE"
              ) {
                // console.log("typeee=========" + result[i]._source.section_type);
                var obj = {
                  sectionName: result[i]._source.section_name,
                  sectionId: result[i]._source.section_id,
                  sectionType: result[i]._source.section_type,
                  sectionImage: result[i]._source.section_image_with_path,
                  isOnline: result[i]._source.doctor_online,
                  subSectionName: result[i]._source.sub_section_name,
                  hasInperson:
                    result[i]._source.ishosonline &&
                    this.state.doctorServices.toString().includes("4")
                      ? "1"
                      : "0",
                  hasVideo:
                    result[i]._source.is_video_enabled === "1" &&
                    this.state.doctorServices.toString().includes("1")
                      ? "1"
                      : "0",
                  hasCall:
                    result[i]._source.is_call_enabled &&
                    this.state.doctorServices.toString().includes("5")
                      ? "1"
                      : "0",
                  hasChat:
                    result[i]._source.is_chat_enabled &&
                    this.state.doctorServices.toString().includes("2")
                      ? "1"
                      : "0",
                  hospital_id: result[i]._source.hospital_id,
                };
                if (result[i]._source.section_type === "DOCTOR") {
                  doctorResult.push(obj);
                } else if (result[i]._source.section_type === "HOSPITAL") {
                  hospitalResult.push(obj);
                } else {
                  specialityResult.push(obj);
                }
              }
            }
            // finalResult = $.merge(specialityResult, doctorResult);
            // finalResult = $.merge(finalResult, hospitalResult);
            // var dataObj = {
            //   specialityResult : specialityResult,
            //   doctorResult : doctorResult,
            //   hospitalResult : hospitalResult,
            // }
            //console.log('dataObj.........',dataObj)
            // finalResult.push(dataObj)

            if (result.length > 0) {
              // console.log("doctorResult====", doctorResult);
              // console.log("specialityResult====", specialityResult);
              // console.log("hospitalResult====", hospitalResult);
              this.setState({
                doctorResult: doctorResult,
                specialityResult: specialityResult,
                hospitalResult: hospitalResult,
                noData: true,
              });
              $(".searchBoxDropdown").show();
            } else {
              $(".searchBoxDropdown").show();
              this.setState({ searchResult: [], noData: false });
            }
          }
        }
      );
    } else {
      $(".searchBoxDropdown").hide();
      this.setState({ searchResult: [], noData: true });
    }

    /*  $(".searchBoxDropdown").show();
      const searchValue = $("#doctorSearch").val();
      const jsonData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "search",
          searchKeyword: searchValue,
          siteId: window.sessionStorage.getItem("siteId"),
          max: "30", // this.state.max,
          offset: "0", //this.state.offset,
          themeName: "",
        }),
      };

    apiCalling(jsonData).then((data) => {
      if (data.success == "1") {
        var cnt = 0;
        if (data.hospitalResult) {
          this.setState({ hospitalResult: data.hospitalResult });
          this.setState({ noData: true });
          cnt = cnt + data.hospitalResult.length;
        }
        if (data.doctorResult) {
          this.setState({ doctorResult: data.doctorResult });
          this.setState({ noData: true });
          cnt = cnt + data.doctorResult.length;
        }
        this.setState({ searchResultCnt: cnt });
      } else {
        //alert(data.errorMessage);
        this.setState({
          searchResult: [],
        });
      }
    });*/
  }

  render() {
    //alert(this.state.doctorlist.length)
    return (
      <div className="purpleWrap">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
          </section>
        </Suspense>
        <Helmet>
          <title>
            Doctor's{" "}
            {window.sessionStorage.getItem("siteName")
              ? " | " + window.sessionStorage.getItem("siteName")
              : ""}
          </title>
          <meta
            name="description"
            content={
              window.sessionStorage.getItem("siteName")
                ? "Doctor's | " + window.sessionStorage.getItem("siteName")
                : "Doctor's"
            }
          />
        </Helmet>
        <div class="loader"></div>
        <div className="container">
          <div className="telehealthfeaturehead text-md-left text-center">
            Doctors
            {/* <div className="doctors-list-tabmain">
              <a href="/chat/chatmessagegroup" className="doctors-list-tab">
                Previous Chats
              </a>
              <a
                href="/home/doctor-list-main/0"
                className="doctors-list-tab doctors-list-tab-active"
              >
                Doctors{" "}
              </a>
              <a
                href="#"
                className="doctors-list-tab"
                onClick={() => this.onlineDoctor("online-doctors")}
              >
                Online Doctors
              </a>
            </div> */}
          </div>
          <div className="filter-search-wrapper">
            <div className="drSearch">
              <input
                onKeyDown={this._handleKeyDown}
                autoComplete="off"
                type="text"
                className="drSearchBox"
                id="doctorSearch"
                placeholder="Doctors, Hospitals, Specialities, Locations"
                onChange={() => this.searchClick()}
              ></input>
              <img
                alt=""
                src={search}
                width="16"
                onClick={() => this.enterSearch()}
              ></img>

              {/* *************doctor search dropdown************ */}
              <div className="searchBoxDropdown" id="searchBoxDropdown">
                {this.state.noData === false ? (
                  <div className="noDoctorList">No results found</div>
                ) : (
                  <div className="searchBoxFound">
                    <div className="searchBoxCount">
                      {this.state.doctorResult.length > 0 ? (
                        <span>DOCTORS</span>
                      ) : (
                        ""
                      )}
                      <span
                        className="link-color"
                        style={{
                          display:
                            this.state.doctorResult.length > 3
                              ? "block"
                              : "none",
                          fontSize: "13px",
                        }}
                        onClick={() => this.enterSearch()}
                      >
                        Show More Doctors
                      </span>
                    </div>

                    <div className="searchBoxList">
                      {this.state.doctorResult.slice(0, 3).map((data) => (
                        <div className="searchBoxItem">
                          <div className="searchDocImg">
                            <Link
                              to={{
                                // pathname: "/home/doctor-detail",
                                pathname:
                                  "/Doctor-" +
                                  data.sectionId +
                                  "-" +
                                  data.sectionName,
                                doctorId: data.sectionId,
                                hospitalId: data.hospital_id,
                              }}
                            >
                              <img src={data.sectionImage} alt="" />
                            </Link>
                          </div>
                          <div className="searchDocDeatils">
                            <Link
                              to={{
                                // pathname: "/home/doctor-detail",
                                pathname:
                                  "/Doctor-" +
                                  data.sectionId +
                                  "-" +
                                  data.sectionName,
                                doctorId: data.sectionId,
                                hospitalId: data.hospital_id,
                              }}
                            >
                              <div className="searchDocName">
                                {data.sectionName}
                              </div>
                              <div className="searchDocDesignation">
                                {data.subSectionName}
                              </div>
                            </Link>
                            <div className="searchDocService">
                              {data.hasInperson === "1" ? (
                                <span
                                  title="Book Appointment"
                                  className="searchServiceIcon"
                                >
                                  <img src={booking} alt="" />
                                </span>
                              ) : (
                                ""
                              )}
                              {data.hasVideo === "1" ? (
                                <span
                                  title="Video Chat"
                                  className="searchServiceIcon"
                                >
                                  <img src={video} alt="" />
                                </span>
                              ) : (
                                ""
                              )}
                              {data.hasCall === "1" ? (
                                <span
                                  title="Call A Doctor"
                                  className="searchServiceIcon"
                                >
                                  <img src={call} alt="" />
                                </span>
                              ) : (
                                ""
                              )}
                              {data.hasChat === "1" ? (
                                <span
                                  title="Doctor Chat"
                                  className="searchServiceIcon"
                                >
                                  <img src={chat} alt="" />
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="searchBoxList">
                      {this.state.specialityResult.map((data) => (
                        <div className="searchBoxItem">
                          <div className="searchDocDeatils searchSplDeatils">
                            <Link
                              onClick={() =>
                                this.loadData(
                                  data.sectionId,
                                  data.sectionType,
                                  data.sectionName
                                )
                              }
                            >
                              <div className="searchDocName">
                                {data.sectionName}
                              </div>
                              {data.subSectionName != "null" ? (
                                <div className="searchDocDesignation">
                                  {data.subSectionName}
                                </div>
                              ) : (
                                ""
                              )}
                            </Link>
                            <div className="searchDocService">
                              {data.sectionType}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="searchBoxList">
                      {this.state.hospitalResult.map((data) => (
                        <div className="searchBoxItem">
                          <div
                            className="searchDocImg"
                            onClick={(e) =>
                              this.loadData(
                                data.sectionId,
                                data.sectionType,
                                data.sectionName
                              )
                            }
                          >
                            <img src={data.sectionImage} alt="" />
                          </div>
                          <div className="searchDocDeatils">
                            <Link
                              onClick={(e) =>
                                this.loadData(
                                  data.sectionId,
                                  data.sectionType,
                                  data.sectionName
                                )
                              }
                            >
                              <div className="searchDocName">
                                {data.sectionName}
                              </div>
                              <div className="searchDocDesignation">
                                {data.subSectionName}
                              </div>
                            </Link>
                            <div className="searchDocService">
                              {data.sectionType}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              {/* *************doctor search dropdown************ */}
            </div>

            {this.state.sectionName != "" ? (
              <div className="drClearSearch">
                <div className="drClearSearchSub">
                  Search result for "{this.state.sectionName}"
                  <span onClick={() => this.clearSearch()}>X</span>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="doctorDetailMain my-4">
            {this.state.doctorlist.map((obj, index) => {
              return (
                <DoctorList
                  docData={obj._source}
                  drService={obj.services}
                  consti={index}
                  doctorServices={this.state.doctorServices}
                  favouriteDoctorsList={this.state.favouriteDoctorsList}
                ></DoctorList>
              );
            })}
          </div>
          <div className="text-center">
            {this.state.doctorDataCount > 24 && (
              <Pagination
                hideFirstLastPages
                activePage={this.state.activePage}
                itemsCountPerPage="24"
                totalItemsCount={this.state.doctorDataCount}
                pageRangeDisplayed="10"
                onChange={this.handlePageChange.bind(this)}
              />
            )}
          </div>
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
