import React, { Component, Suspense } from "react";
import defaulticon from "../../image/icons/defaulticon.jpg";
import { apiCalling } from "../../apiService";
import $ from "jquery";
import removeicon from "../../image/icons/deactvate.png";
import AWS from "aws-sdk";
import Header from "../../Reliance/header"
import Footer from "../../Reliance/footer"
import { uploadToS3 } from "../../UploadFile";

var s3;
let fullFilePath


export default class Addimages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hospitalId: '',
      hospitalName: '',
      hospitalImages: [],
      imageArray: [],
      imageListingData: [],
      // s3bucketAccessKey: '',
      // AWSAccessKeyId: '',
      region: "us-east-1",
      bucket: '',
      bucketMainFolder: '',
      S3BucketHospitalImages: '',
    };

  }
  componentDidMount() {
    if (this.props.location.state !== undefined) {
      const { hospitalId, hospitalName } = this.props.location.state;
      this.setState({
        hospitalId: hospitalId,
        hospitalName: hospitalName
      });

    }


    this.imageListing();
    this.getAwsPath();
    // Toggle
    $(document).ready(function () {
      $('#show-hidden-menu').click(function () {
        $('.hidden-menu').slideDown();
      });
    });

    // Choose file
    //   function readURL(input, imgControlName) {
    //     if (input.files && input.files[0]) {
    //       var reader = new FileReader();
    //       reader.onload = function (e) {
    //         $(imgControlName).attr('src', e.target.result);
    //       }
    //       reader.readAsDataURL(input.files[0]);
    //     }
    //   }

    //   $("#imag").change(function () {
    //     // add your logic to decide which image control you'll use
    //     var imgControlName = "#ImgPreview";
    //     readURL(this, imgControlName);
    //     $('.preview1').addClass('it');
    //     $('.btn-rmv1').addClass('rmv');
    //   });
    //   $("#imag2").change(function () {
    //     var imgControlName = "#ImgPreview2";
    //     readURL(this, imgControlName);
    //     $('.preview2').addClass('it');
    //     $('.btn-rmv2').addClass('rmv');
    //   });
    //   $("#imag3").change(function () {
    //     var imgControlName = "#ImgPreview3";
    //     readURL(this, imgControlName);
    //     $('.preview3').addClass('it');
    //     $('.btn-rmv3').addClass('rmv');
    //   });
    //   $("#imag4").change(function () {
    //     var imgControlName = "#ImgPreview4";
    //     readURL(this, imgControlName);
    //     $('.preview4').addClass('it');
    //     $('.btn-rmv4').addClass('rmv');
    //   });
    //   $("#imag5").change(function () {
    //     var imgControlName = "#ImgPreview5";
    //     readURL(this, imgControlName);
    //     $('.preview5').addClass('it');
    //     $('.btn-rmv5').addClass('rmv');
    //   });

    //   $("#removeImage1").click(function (e) {
    //     e.preventDefault();
    //     $("#imag").val("");
    //     $("#ImgPreview").attr("src", "");
    //     $('.preview1').removeClass('it');
    //     $('.btn-rmv1').removeClass('rmv');
    //   });
    //   $("#removeImage2").click(function (e) {
    //     e.preventDefault();
    //     $("#imag2").val("");
    //     $("#ImgPreview2").attr("src", "");
    //     $('.preview2').removeClass('it');
    //     $('.btn-rmv2').removeClass('rmv');
    //   });
    //   $("#removeImage3").click(function (e) {
    //     e.preventDefault();
    //     $("#imag3").val("");
    //     $("#ImgPreview3").attr("src", "");
    //     $('.preview3').removeClass('it');
    //     $('.btn-rmv3').removeClass('rmv');
    //   });
    //   $("#removeImage4").click(function (e) {
    //     e.preventDefault();
    //     $("#imag4").val("");
    //     $("#ImgPreview4").attr("src", "");
    //     $('.preview4').removeClass('it');
    //     $('.btn-rmv4').removeClass('rmv');
    //   });
    //   $("#removeImage5").click(function (e) {
    //     e.preventDefault();
    //     $("#imag5").val("");
    //     $("#ImgPreview5").attr("src", "");
    //     $('.preview5').removeClass('it');
    //     $('.btn-rmv5').removeClass('rmv');
    //   });
  }

  getAwsPath() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "imagePath",
        siteId: window.sessionStorage.getItem("siteId"),
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        // this.setState({
        //   s3bucketAccessKey: data.configPathDetails[0].s3bucketAccessKey,
        // });
        // this.setState({
        //   AWSAccessKeyId: data.configPathDetails[0].s3bucketSecretKey,
        // });

        this.setState({
          bucket: data.configPathDetails[0].PurpleHealthS3bucket,
        });
        this.setState({
          bucketMainFolder: data.configPathDetails[0].S3BucketMainFolder,
        });
        this.setState({
          S3BucketHospitalImages: data.configPathDetails[0].S3BucketHospitalImages,
        });

      }
    });
  }

  uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  imageAppend() {
    var flag = false
    if (this.state.imageArray.length > 0) {
      for (var i = 0; i <= this.state.imageArray.length; i++) {
        if ($("#txtImageFile" + i).val() === "") {
          // alert("Image Required")
          $("#txtImageFile" + i).focus();
          flag = true;
          break;
        }
      }
    }
    if (flag === false) {
      var b = {
        "imageName": "",
      }
      this.state.imageArray.push(b)
      this.setState({
        imageArray: this.state.imageArray
      })
    }

  }

  handleImageUpload(e, i) {
    var file = $('#txtImageFile' + i)[0].files[0];
    $("#imgDiv" + i).show()
    let reader = new FileReader();
    var img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = function () {
      reader.onload = function (e) {
        $("#thumbImgnormal" + i).attr('src', e.target.result);
      };
      reader.readAsDataURL(file);
    };
    let fileName = this.uuidv4() + $('#txtImageFile' + i)[0].files[0].name;
    this.state.imageArray[i].imageName = fileName;
    this.state.imageArray[i].imageObject = file;
  }

  async fileUpload(i, fileName) {
    // AWS.config.update({
    //   accessKeyId: this.state.s3bucketAccessKey,
    //   secretAccessKey: this.state.AWSAccessKeyId,
    //   region: this.state.region,
    // });
    // var bucketParams = {
    //   Bucket: this.state.bucket + "/" + this.state.bucketMainFolder + "/" + this.state.S3BucketHospitalImages,
    // };
    // s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    var file = $('#txtImageFile' + i)[0].files[0];
    // let S3BucketMainFolder = ''
    let fullFilePath = this.state.bucketMainFolder + "/" + fileName;
    const s3url = await uploadToS3(file, this.state.bucketMainFolder + "/" + this.state.S3BucketHospitalImages, fileName, this.state.bucket)
    if (s3url) {
      alert("successfully uploaded")
      return null
    } else {
      alert("There was an error uploading your photo")
    }
    // s3.upload(
    //   {
    //     Key: fullFilePath,
    //     Body: file,
    //     ACL: "public-read"
    //   },
    //   (err, data) => {
    //     if (err) {
    //       alert("There was an error uploading your photo: ", err.message);
    //     }
    //     else if (data) {
    //       // alert("successfully uploaded", data.message);
    //       return null;
    //     }
    //   }
    // )
  }

  imageRemove(i) {

    const list = this.state.imageListingData;
    list.splice(i, 1)
    this.setState({ imageArray: list })
  }

  saveHandle() {
    if (this.state.imageArray.length !== 0) {
      var itemImageArray = [];
      for (var j = 0; j < this.state.imageArray.length; j++) {
        let fileName = this.state.imageArray[j].imageName;
        var b = fileName
        itemImageArray.push(b)
        if ($("#txtImageFile" + j).val() !== "") {
          this.fileUpload(j, fileName)
        }
      }
      const saveImageData = {
        method: 'POST',
        body: JSON.stringify({
          functionName: "saveHospitalImageInformation",
          hospitalId: this.props.match.params.hospitalId,
          arrayImageList: itemImageArray,
        })
      }
      $(".loader").show();
      apiCalling(saveImageData).then((data) => {
        $(".loader").hide();
        if (data.success === "1") {
          alert("Saved successfully")
          setTimeout(() => {
            window.location.reload(false);
          }, 4000);
        } if (data.success === "0") {
          alert(data.errorMessage)
        }
      })
    }
    else {
      alert("Please Select Image")
    }
  }

  imageListing() {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getHospitalImageInformation",
        hospitalId: this.props.match.params.hospitalId,
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
        this.setState({
          hospitalId: data.hospitalId,
          hospitalName: data.hospitalName,
          imageListingData: data.hospitalImageData,
        });
      }
      if (data.success === "0") {
        $(".loader").hide();
        this.setState({
          hospitalId: '',
          hospitalName: '',
          imageArray: [],
        });
      }
    });
  }


  srcTypeImage(ev) {
    ev.target.src = defaulticon
  }

  cancelHandle() {

    window.location.href = "/adminManagement/hospitalManagementList/hospitalManagementList"
  }
  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />
            <div className="container mb-5">
              <div>
                <ul className="breadcrum-adminMangmnt">
                  <li><a href="/admin/dashboardAdmin">Dashboard</a></li>
                  <li><a href="/adminManagement/hospitalManagementList/hospitalManagementList">Hospital Management</a></li>
                  <li><a href="" className="current">Add Images</a></li>
                </ul>
              </div>
              <div className="smssetng-btn">
                <input type="submit" value="Save" className="smssetng-svebtn" onClick={() => this.saveHandle()} />
                <input type="submit" value="Cancel" className="smssetng-cancelbtn" onClick={() => this.cancelHandle()} />
              </div>
              <div className="ph-addimagewraper doctr-wraper-border-addimge">
                <div className="hspInformation-wraper ph-add-images">
                  <h2 class="hspInfoSubhead pb-4">Hospital Information</h2>
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="row">
                          <div className="form-group addimgfield-div">
                            <label>Hospital Id</label>
                            <input type="text" className="form-control" value={this.props.location.state.hospitalId} />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="row">
                          <div className="form-group addimgfield-div">
                            <label for="" class="smalLbl">Hospital Name<span class="hspifoMantory">*</span></label>
                            <input type="text" className="form-control" defaultValue={this.props.location.state.hospitalName} />
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className="row">
                      <div className="col-lg-12  pl-0">
                        <div class="addimgfield-div">
                          <label for="" class="smalLbl">Image<span class="hspifoMantory">*</span></label>
                          <button className="addImagebtn-hspManagement" id="show-hidden-menu" onClick={() => this.imageAppend()} >Add Images</button>
                        </div>
                        <div class="hidden-menu hidden-menuImgUplod mt-4" style={{ display: 'none' }}>
                          <h2 class="hspInfoSubhead pb-4" >Image Upload</h2>

                          {this.state.imageArray.map((imageObj, i) => (
                            <div className="yes" id={"imageDiv" + i}>
                              <div className="btn_upload">
                                <input type="file" id={"txtImageFile" + i} name="txtImageFile" accept="image/*" onChange={(e) => this.handleImageUpload(e, i)} />
                                <label for={"txtImageFile" + i}>Upload</label>
                              </div>
                              <div className="imgUploadInline-hspaddimg" style={{ display: 'none' }} id={"imgDiv" + i}><img id={"thumbImgnormal" + i} /></div>
                              <img src={removeicon} className="imgCloseImg-hspadd pl-1" onClick={(e) => this.imageRemove(i)} />
                            </div>
                          ))}

                          {/* <div className="">
                            <form id="myform">
                              <div class="yes">
                                <div class="btn_upload">
                                  <input type="file" id="imag" title="" class="input-img" />
                                  Choose File
                                </div>
                                <div className="addimgprevwImgdiv">
                                  <img id="ImgPreview" src="" class="preview1" />
                                  <input type="button" id="removeImage1" value="&#10006;" class="btn-rmv1" />
                                </div>
                              </div>
                            </form>
                          </div> */}
                        </div>
                        {this.state.imageListingData.length > 0 ?
                          <div className="ph-imglist">
                            <div className="row">
                              <div>
                                <div className="row">
                                  {this.state.imageListingData.map((data, i) => (
                                    <div className="ph-uploadedimg">
                                      <img onError={this.srcTypeImage} src={data.imageNameWithUrl} alt="" />
                                      <input type="button" id="removeImage1" value="&#10006;" class="rmvbtn-imgeuplodHsp" onClick={(e) => this.imageRemove(i)} />
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          : ""}
                        <div className="smssetng-btn mt-3">
                          <input type="submit" value="Save" className="smssetng-svebtn" onClick={() => this.saveHandle()} />
                          <input type="submit" value="Cancel" className="smssetng-cancelbtn" onClick={() => this.cancelHandle()} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Suspense>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
