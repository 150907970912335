import React, { Component, Suspense } from "react";
import ReactStars from "react-rating-stars-component";
import moment from "moment";
import { apiCalling, awsAPIUrlcommon } from "../../apiService";
import $ from "jquery";
const crypto = require("crypto");
var decipher = "";
var decryptedUrl = "";
var successFlag = true;
var algorithm = "aes256";
var key = "purple123";
export default class DCadminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reactStarvalue: 0,
      feedBackText: "",
      feedBackStatus: 0,
      doctotName: "",
      PatientNamefirstName: "",
      PatientNameLastName: "",
      videoChatDate: "",
      doctorLastName: "",
      thankYou: true,
      thankYouMessage: "",
    };
  }
  componentDidMount() {
    // console.log("hello");
    const { match } = this.props;
    this.gettingStatusId(match.params.token);
  }
  gettingStatusId = (value) => {
    const postData = {
      method: "POST",
      body: JSON.stringify({
        minifiedId: value,
        functionName: "getSiteUrlByMinifiedId",
      }),
    };
    // console.log("postData", postData.body);
    awsAPIUrlcommon(postData).then((data) => {
      // console.log("hello", data.siteUrl);
      let token = data.siteUrl.split("-").pop();
      // console.log(
      //   "🚀 ~ file: feedback.jsx:43 ~ DCadminDashboard ~ awsAPIUrlcommon ~ token:",
      //   token
      // );
      // var text = token;
      decipher = crypto.createDecipher(algorithm, key);
      decryptedUrl =
        decipher.update(token, "hex", "utf8") + decipher.final("utf8");
      this.getjournalDetails(decryptedUrl);
    });
  };
  countReactStar = (value) => {
    this.setState({
      reactStarvalue: value,
    });
  };
  getjournalDetails(statusId) {
    // debugger
    const postData = {
      method: "POST",
      body: JSON.stringify({
        requestVideoAppointmentId: statusId.split("-")[0],
        functionName: "getPatientVideoAudioFeedbackStatus",
      }),
    };
    $(".loader").show();
    apiCalling(postData).then((data) => {
      if (data.success === "1") {
        if (data.result?.feedBackStatus == 1) {
          // alert("You have already submitted your feedback. Thank you.");
          this.setState({
            thankYou: true,
            thankYouMessage:
              "You have already submitted your feedback. Thank you.",
          });
        } else {
          this.setState({
            thankYou: false,
            thankYouMessage: "",
          });
        }
        this.countReactStar(
          parseInt(data.result.appointmentDetails[0].feedbackRating)
        );
        this.setState({
          feedBackStatus: data.result.feedBackStatus,
          doctotName: data.result.appointmentDetails[0].doctorFirstName,
          doctorLastName: data.result.appointmentDetails[0].doctorLastName,
          PatientNamefirstName:
            data.result.appointmentDetails[0].memberFirstName,
          PatientNameLastName: data.result.appointmentDetails[0].memberLastName,
          videoChatDate:
            data.result.appointmentDetails[0].requestedAppointmentDate,
          feedBackText: data.result.appointmentDetails[0].feedbackComment,
        });
      }
      $(".loader").hide();
    });
  }
  saveReview = () => {
    const postData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "saveCallRating",
        requestVideoAppointmentId: decryptedUrl.split("-")[0],
        rating: this.state.reactStarvalue,
        comment: this.state.feedBackText,
      }),
    };
    $(".loader").show();
    apiCalling(postData).then((data) => {
      $(".loader").hide();
      if (data.success === "1") {
        this.setState({
          thankYou: true,
          thankYouMessage: "Thank you for your response",
        });
        // alert("Thank you for your response");
        // window.close();
      }
    });
  };
  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead">
        {this.state.thankYou == false ? (
          <div className="container  mt-3 mb-3 feedback-form">
            <h4 className="mb-3 text-center">Rate Your Experience</h4>
            <div className="row">
              <div className="col-lg-12 mt-2 text-center">
                <label htmlFor="">
                  Doctor Name :{" "}
                  <span>
                    {this.state.doctotName} {this.state.doctorLastName}
                  </span>
                </label>
              </div>
              <div className="col-lg-12 mt-2 text-center">
                <label htmlFor="">
                  Patient Name :{" "}
                  <span>
                    {this.state.PatientNamefirstName}{" "}
                    {this.state.PatientNameLastName}{" "}
                  </span>
                </label>
              </div>
              <div className="col-lg-12 mt-2 text-center">
                <label htmlFor="">
                  Video Chat Date :{" "}
                  <span>
                    {" "}
                    {moment(this.state.videoChatDate).format("DD/MM/YYYY")}
                  </span>
                </label>
              </div>
              <div className="col-lg-12 ratingFlex">
                <label htmlFor="" className="mt-3">
                  Rating :
                </label>
                <ReactStars
                  count={5}
                  key={this.state.reactStarvalue}
                  size={24}
                  value={this.state.reactStarvalue}
                  activeColor="#F4790B"
                  onChange={this.countReactStar}
                />
              </div>

              <div className="col-lg-12 text-center mt-2">
                {this.state.feedBackStatus != 1 ? (
                  <label id="feedBackText" htmlFor="">
                    Please leave your feedback below
                  </label>
                ) : (
                  ""
                )}
                <textarea
                  className="form-control"
                  value={this.state.feedBackText}
                  onChange={(e) =>
                    this.setState({
                      feedBackText: e.target.value,
                    })
                  }
                ></textarea>
              </div>
            </div>
            {this.state.reactStarvalue !== 0 &&
            this.state.feedBackStatus != 1 ? (
              <div className="text-center">
                <button
                  className="btn btn-primary mt-3"
                  onClick={this.saveReview}
                >
                  Submit
                </button>
              </div>
            ) : null}
            {this.state.feedBackStatus == 1 ? (
              <div className="text-center">
                <button className="btn btn-primary mt-3">
                  You have already rated your experience
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="thankYouMessage">
            <h4 className="text-center"> {this.state.thankYouMessage}</h4>
          </div>
        )}
      </div>
    );
  }
}
