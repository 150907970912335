import React, { Component, Suspense } from "react";
import HospitalInfomation from "./hospitalInformation";
import GoogleMap from "./GoogleMap";
import HospitalworkDetails from "./hospitalworkDetails";
import HospitalSpecialisation from "./hospitalSpecialisation";
import BankAccountdetails from "./bankAccountdetails";
import ConsultingTime from "./consultingTime";
import VisitingTime from "./visitingTime";
import SuperAdmin from "./superAdmin";
import Servicedetails from "./servicedetails";
import HospitalAdmindetails from "./hospitalAdmindetails";
import { apiCalling } from "../../apiService";
import $ from "jquery";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Link } from "react-router-dom";
import Header from "../../Reliance/header";
import Footer from "../../Reliance/footer";
import AWS from "aws-sdk";
import { uploadToS3 } from "../../UploadFile";

var s3;
var fileNameUploadSignature;

export default class AddnewHsp extends Component {
  state = {
    slug: "",
    seoTitle: "",
    seoKeywords: "",
    seoDescription: "",
    backgroundColour: "#FFFFFF",
    foregroundColour: "#FFFFFF",
    buttonColour: "#FFFFFF",
    fontColour: "#FFFFFF",
    hospitalId: "",
    HospitalworkDetailsArray: [],
    hospitalDataArray: [],
    specializationIdEdit: [],
    hospitalVisitingTimeEdit: [],
    hospitalConsultingTimeEdit: [],

    fileSignature: null,

    CredentialData: [],
    getTypeData: [],
    ClassificataionData: [],
    getPaymentTypeData: [],
    timeFilterData: [],
    stateListData: [],
    cityListData: [],
    countryData: [],
    subCitylistData: [],
    categoryData: [],

    imagepathDemo: "",
    longitude: "",
    latitude: "",
    googleAddressPin: "",
    associationIds: "",

    // s3bucketAccessKey: "",
    // s3bucketSecretKey: "",
    s3BucketMainFolder: "",
    s3BucketAddressPath: "",
    s3BucketDoctorImages: "",
    s3Bucket: "",

    hospitalName: "",
    hospitalEmail: "",
    hospitalAddress: "",
    cmbCountry: "1",
    pinCode: "",
    phone: "",
    primaryEmailIds: "",
    categoryId: "2",
    credentialId: "1",
    typeId: "2",
    paymentTypeIds: [27],
    workTimeFilter: [1, 2],
    classificationIds: [1],
    uploadedImageName: "",
    uploadedImageNameWithPath: "",
    stateId: "0",
    cityId: "0",
    subCity: "0",
    specializationData: [],
    servicesData: [],
    hospitalServiceDetailsArray: [],
    hospitalImage: "",
  };

  changeHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  changeHandlerMulti = (e) => {
    const selectedOptions = [...e.target.selectedOptions].map(
      (opt) => opt.value
    );
    this.setState({ [e.target.id]: selectedOptions });
  };

  getCategory = () => {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getCategoryData",
        categoryId: "",
      }),
    };

    apiCalling(apiJson).then((data) => {
      this.setState(
        {
          categoryData: data,
        }
        // ,
        // () => {
        //   $('#categoryId option[value="2"]').attr("selected", true);
        // }
      );
    });
  };

  listing = () => {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getDropdownLists",
      }),
    };

    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState(
          {
            // s3bucketAccessKey: data.result[0].s3bucketAccessKey,
            // s3bucketSecretKey: data.result[0].s3bucketSecretKey,
            s3BucketMainFolder: data.result[0].S3BucketMainFolder,
            s3BucketAddressPath: data.result[0].s3BucketAddressPath,
            S3BucketHospitalImages: data.result[0].S3BucketHospitalImages,
            s3Bucket: data.result[0].s3Bucket,
          },
          () => {
            // AWS.config.update({
            //   accessKeyId: this.state.s3bucketAccessKey,
            //   secretAccessKey: this.state.s3bucketSecretKey,
            //   region: "us-east-1",
            // });

            // var bucketParams = {
            //   Bucket: this.state.s3Bucket,
            // };

            // s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
          }
        );
      }
    });
  };

  getHospitalDropdown = () => {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getHospitalDropdown",
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState(
          {
            CredentialData: data.hospitalData[0].CredentialData,
            getTypeData: data.hospitalData[0].getTypeData,
            ClassificataionData: data.hospitalData[0].ClassificataionData,
            getPaymentTypeData: data.hospitalData[0].getPaymentTypeData,
            timeFilterData: data.hospitalData[0].timeFilterData,
            imagepathDemo:
              "https://purplehealthfiles.s3.amazonaws.com/test/hospitalimages/",
            specializationData: data.hospitalData[0].specializationData,
            servicesData: data.hospitalData[0].serviceData,
          }
          /* ,() => {
            // $('#credentialId option[value="1"]').attr("selected", true);
            // $('#typeId option[value="2"]').attr("selected", true);
            // $('#workTimeFilter option[value="1"]').attr("selected", true);
            // $('#workTimeFilter option[value="2"]').attr("selected", true);
            // $('#paymentTypeIds option[value="27"]').attr("selected", true);
            // $('#classificationIds option[value="1"]').attr("selected", true);

            if (this.props.hospitalData1.length > 0) {
              // $("#hospitalId").val(this.props.hospitalId);
              //  $("#hospitalName").val(this.props.hospitalData1[0].name);
              //  $("#hospitalEmail").val(this.props.hospitalData1[0].emailId);
              //  $("#hospitalAddress").val(this.props.hospitalData1[0].address);
              //  $("#cmbCountry").val(this.props.hospitalData1[0].countryId);
              //  $("#pinCode").val(this.props.hospitalData1[0].pinCode);
              //  $("#phone").val(this.props.hospitalData1[0].phone);
              //  $("#primaryEmailIds").val(this.props.hospitalData1[0].emailId);
              //  $("#categoryId").val(this.props.hospitalData1[0].categoryId);
              //  $("#credentialId").val(this.props.hospitalData1[0].credentialId);
              //  $("#typeId").val(this.props.hospitalData1[0].typeId);
              

              $("#paymentTypeIds").val(_paymentTypeIdEdit);
              $("#workTimeFilter").val(this.props.timeFilterEdit);

              if (this.state.uploadedImageName !== "") {
                $("#imgDiv").show();
                $("#image").attr(
                  "src",
                  this.state.uploadedImageNameWithPath
                );
              }

              this.getCountryList(
                this.state.stateId,
                this.state.cityId,
                this.state.subCity
              );
            } else {
              this.getCountryList();
            }
          }*/
        );
      }
    });
  };

  getCountryList = (stateId, cityId, subCity) => {
    let _stateId =
      stateId === "" || stateId === undefined ? this.state.stateId : stateId;
    let _cityId =
      cityId === "" || cityId === undefined ? this.state.cityId : cityId;
    let _subCity =
      subCity === "" || cityId === undefined ? this.state.subCity : subCity;

    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getCountryList",
        countryId: "1",
        stateId: _stateId === "0" ? "" : _stateId,
        cityId: _cityId === "0" ? "" : _cityId,
      }),
    };
    $(".loader").show();
    apiCalling(apiJson).then((data) => {
      this.setState(
        {
          countryData: data.countryList,
          stateListData: data.stateList,
          cityListData: data.cityList,
          subCitylistData: data.subCitylist,
        },
        () => {
          $("#cmbState").val(
            _stateId === undefined || _stateId === null || _stateId === ""
              ? "0"
              : _stateId
          );

          $("#cmbCity").val(
            _cityId === undefined || _cityId === null || _cityId === ""
              ? "0"
              : _cityId
          );

          $("#cmbSubCity").val(
            _subCity === undefined || _subCity === null || _subCity === ""
              ? "0"
              : _subCity
          );
          $(".loader").hide();
        }
      );
    });
  };

  uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;

        return v.toString(16);
      }
    );
  };
  hospitalinformation() {
    var image = "";
    if (this.state.fileSignature !== null) {
      this.fileUpload(this.state.fileSignature, "attachImg");
      image = fileNameUploadSignature;
    } else {
      image = fileNameUploadSignature;
    }
    return image;
  }

  async fileUpload(file, mode) {
    let reader = new FileReader();
    // let s3BucketMainFolder1 = this.state.s3BucketMainFolder + "/" + this.state.S3BucketHospitalImages;

    let fileName = this.uuidv4() + file.name;
    let fullFilePath = this.state.s3BucketMainFolder + "/" + this.state.S3BucketHospitalImages + "/" + fileName;
    fileNameUploadSignature = fullFilePath;

    if (mode === "attachImg") {
      fileNameUploadSignature = fullFilePath;
    }

    const s3url = await uploadToS3(file, this.state.s3BucketMainFolder + "/" + this.state.S3BucketHospitalImages, fileName, this.state.s3Bucket)

    if (s3url) {
      fileNameUploadSignature = s3url
    } else {
      alert("error in uploading image")
    }
    // s3.upload(
    //   {
    //     Key: fullFilePath,
    //     Body: file,
    //     ACL: "public-read",
    //   },

    //   (err, data) => {
    //     if (err) {
    //       alert("Error uploading Image");
    //     } else {
    //       //return fullFilePath;
    //       fileNameUploadSignature = data.Location;
    //     }
    //   }
    // );
  }

  componentDidMount() {
    this.getCategory();
    this.listing();
    this.getHospitalDropdown();

    if (this.props.location.state !== undefined) {
      const { hospitalId } = this.props.location.state;
      this.setState({
        hospitalId: hospitalId,
      });

      if (hospitalId > 0) {
        this.editHospitalData(hospitalId);
      }
    } else {
      this.getCountryList();
    }
  }

  editHospitalData = (hospitalId) => {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getHospitalDetails",
        hospitalId: hospitalId,
      }),
    };

    $(".loader").show();
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        let insuranceIdEdit = [];
        let accreditationIdEdit = [];
        let timeFilterEdit = data.hospitalData[0].timeFilter.split(",");
        let paymentTypeIdEdit = [];
        let hospitalVisitingTimeEdit = [];
        let hospitalConsultingTimeEdit = [];
        let hospitalServiceDetailsArray = [];

        data.hospitalData[0].hospitalInsuranceId.map((data) => {
          insuranceIdEdit.push(data.insuranceId);
        });

        data.hospitalData[0].hospitalAccreditationId.map((data) => {
          accreditationIdEdit.push(data.accreditationId);
        });

        data.hospitalData[0].paymentTypeId.map((data) => {
          paymentTypeIdEdit.push(data.paymentTypeId);
        });

        data.hospitalData[0].hospitalVisitingTime.map((data) => {
          let vt = data.visitingTime.split("-");
          hospitalVisitingTimeEdit.push({
            visitingTimeFrom: vt[0],
            visitingTimeTo: vt[1],
          });
        });

        data.hospitalData[0].hospitalConsultingTime.map((data) => {
          let ct = data.consultingTime.split("-");
          hospitalConsultingTimeEdit.push({
            consultingTimeFrom: ct[0],
            consultingTimeTo: ct[1],
          });
        });

        data.hospitalData[0].hospitalServiceDetails.map((data) => {
          let st = data.hospitalTiming.split("-");
          hospitalServiceDetailsArray.push({
            hospitalServiceId: data.hospitalServiceId,
            servicesTimeFrom: st[0],
            servicesTimeTo: st[1],
          });
        });

        this.setState({
          slug: data.hospitalData[0].slug,
          seoTitle: data.hospitalData[0].seoTitle,
          seoKeywords: data.hospitalData[0].seoKeywords,
          seoDescription: data.hospitalData[0].seoDescription,
          hospitalDataArray: data.hospitalData,

          hospitalName: data.hospitalData[0].name,
          hospitalEmail: data.hospitalData[0].emailId,
          hospitalAddress: data.hospitalData[0].address,
          hospitalImage: data.hospitalData[0].uploadedImageNameWithPath,
          pinCode: data.hospitalData[0].pinCode,
          phone: data.hospitalData[0].phone,
          primaryEmailIds: data.hospitalData[0].emailId,
          categoryId: data.hospitalData[0].categoryId,
          credentialId: data.hospitalData[0].credentialId,
          typeId: data.hospitalData[0].typeId,
          paymentTypeIds: paymentTypeIdEdit,
          workTimeFilter: timeFilterEdit,
          cmbCountry: data.hospitalData[0].countryId,
          stateId: data.hospitalData[0].stateId,
          cityId: data.hospitalData[0].cityId,
          subCity: data.hospitalData[0].subCity,
          HospitalworkDetailsArray: data.hospitalData[0].hospitalOfficeHours,
          specializationIdEdit: data.hospitalData[0].specializationId,
          hospitalServiceDetailsArray: hospitalServiceDetailsArray,
          hospitalConsultingTimeEdit: hospitalConsultingTimeEdit,
          hospitalVisitingTimeEdit: hospitalVisitingTimeEdit,
          accreditationIdEdit: accreditationIdEdit,
          insuranceIdEdit: insuranceIdEdit,
          latitude: data.hospitalData[0].latitude,
          longitude: data.hospitalData[0].longitude,
          googleAddressPin: data.hospitalData[0].googleAddressPin,
        });

        this.getCountryList(
          data.hospitalData[0].stateId,
          data.hospitalData[0].cityId,
          data.hospitalData[0].subCity
        );
      } else {
        $(".loader").hide();
      }
    });
  };

  isEmail = (email) => {
    var isMailOk = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
      email
    );
    return isMailOk;
  };

  saveHospital = () => {
    let specialisations = this.state.specializationData;
    let servicedetailsData = this.state.hospitalServiceDetailsArray;

    let visitingArrayDetailsData = this.state.hospitalVisitingTimeEdit;
    // var visitingArrayDetailsData = this.refs.visitingTimeElement.VisitingTime();
    // let consultingArrayDetailsData =
    //   this.refs.consultingTimeElement.consultingArrayDetails();
    let consultingArrayDetailsData = this.state.hospitalConsultingTimeEdit;

    // var hospitalImage =
    //   this.refs.hospitalInformationElement.hospitalinformation();

    var hospitalImage = this.hospitalinformation();

    var flag = true;
    //var email = $("#txtEmail").val()
    var phoneno = /^\d{10}$/;
    if ($("#hospitalName").val() === "") {
      alert("Hospital Name Required");
      $("#hospitalName").focus();
      flag = false;
    } else if ($("#hospitalEmail").val() === "") {
      alert("Hospital Email Required");
      $("#hospitalEmail").focus();
      flag = false;
    } else if (this.isEmail($("#hospitalEmail").val()) === false) {
      alert("Invalid Hospital Email id");
      $("#hospitalEmail").focus();
      flag = false;
    } else if ($("#hospitalAddress").val() === "") {
      alert("Hospital Address Required");
      $("#hospitalAddress").focus();
      flag = false;
    } else if ($("#cmbCountry").val() === "") {
      alert("Country Required");
      $("#cmbCountry").focus();
      flag = false;
    } else if ($("#stateId").val() === "0") {
      alert("State Required");
      $("#stateId").focus();
      flag = false;
    } else if ($("#cityId").val() === "0") {
      alert("City Required");
      $("#cityId").focus();
      flag = false;
    } else if ($("#pinCode").val() === "") {
      alert("Pin Code Required");
      $("#pinCode").focus();
      flag = false;
    } else if ($("#phone").val() === "") {
      alert("Hospital Mobile Number Required");
      $("#phone").focus();
      flag = false;
    } else if (!$("#phone").val().match(phoneno)) {
      alert("Please enter valid Mobile Number");
      $("#phone").focus();
      flag = false;
    } else if ($("#primaryEmailIds").val() === "") {
      alert("Primary Email Required");
      $("#primaryEmailIds").focus();
      flag = false;
    } else if (this.isEmail($("#primaryEmailIds").val()) === false) {
      alert("Invalid Primary Email id");
      $("#primaryEmailIds").focus();
      flag = false;
    } else if ($("#categoryId").val() === "0") {
      alert("Category Id Required");
      $("#categoryId").focus();
      flag = false;
    } else if ($("#credentialId").val() === "0") {
      alert("Credential Id Required");
      $("#credentialId").focus();
      flag = false;
    } else if ($("#typeId").val() === "0") {
      alert("Type Id Required");
      $("#typeId").focus();
      flag = false;
    } else if ($("#classificationIds").val().length === "0") {
      alert("Classification Ids Required");
      $("#classificationIds").focus();
      flag = false;
    } else if ($("#paymentTypeIds").val().length === "0") {
      alert("PaymentType Ids Required");
      $("#paymentTypeIds").focus();
      flag = false;
    } else if ($("#workTimeFilter").val().length === "0") {
      alert("WorkTime Filter Ids Required");
      $("#workTimeFilter").focus();
      flag = false;
    } else if ($("#sh1MonFrom").val() === "0") {
      alert("Office Hours Required");
      $("#sh1MonFrom").focus();
      flag = false;
    } else if ($("#sh1MonTo").val() === "0") {
      alert("Office Hours Required");
      $("#sh1MonTo").focus();
      flag = false;
    } else if ($("#sh1TueFrom").val() === "0") {
      alert("Office Hours Required");
      $("#sh1TueFrom").focus();
      flag = false;
    } else if ($("#sh1TueTo").val() === "0") {
      alert("Office Hours Required");
      $("#sh1TueTo").focus();
      flag = false;
    } else if ($("#sh1WedFrom").val() === "0") {
      alert("Office Hours Required");
      $("#sh1WedFrom").focus();
      flag = false;
    } else if ($("#sh1WedTo").val() === "0") {
      alert("Office Hours Required");
      $("#sh1WedTo").focus();
      flag = false;
    } else if ($("#sh1ThurFrom").val() === "0") {
      alert("Office Hours Required");
      $("#sh1ThurFrom").focus();
      flag = false;
    } else if ($("#sh1ThurTo").val() === "0") {
      alert("Office Hours Required");
      $("#sh1ThurTo").focus();
      flag = false;
    } else if ($("#sh1SunFrom").val() === "0") {
      alert("Office Hours Required");
      $("#sh1SunFrom").focus();
      flag = false;
    } else if ($("#sh1SunTo").val() === "0") {
      alert("Office Hours Required");
      $("#sh1SunTo").focus();
      flag = false;
    } else if ($("#sh1FriFrom").val() === "0") {
      alert("Office Hours Required");
      $("#sh1FriFrom").focus();
      flag = false;
    } else if ($("#sh1FriTo").val() === "0") {
      alert("Office Hours Required");
      $("#sh1FriTo").focus();
      flag = false;
    } else if ($("#sh1SatFrom").val() === "0") {
      alert("Office Hours Required");
      $("#sh1SatFrom").focus();
      flag = false;
    } else if ($("#sh1SatTo").val() === "0") {
      alert("Office Hours Required");
      $("#sh1SatTo").focus();
      flag = false;
    } else if (specialisations.length === 0) {
      alert("Specialisations Required");

      flag = false;
    } else if (this.state.associationIds == 0) {
      alert("Choose Association Name");
      $("#associationIds").focus();
      flag = false;
    } else if ($("#adminDisplayName").val() === "") {
      alert("Admin Display Name Required");
      $("#adminDisplayName").focus();
      flag = false;
    } else if ($("#adminUserName").val() === "") {
      alert("Admin User Name Required");
      $("#adminUserName").focus();
      flag = false;
    } else if ($("#adminPassword").val() === "") {
      alert("Password Required");
      $("#adminPassword").focus();
      flag = false;
    } else if ($("#adminPassword").val().length < 6) {
      alert("Password must contain at least 6 characters");
      $("#adminPassword").focus();
      flag = false;
    } else if ($("#deactiveDate").val() === "") {
      alert("Deactive Date Required");
      $("#deactiveDate").focus();
      flag = false;
    } else if ($("#description").val() === "") {
      alert("Description Required");
      $("#description").focus();
      flag = false;
    } else if ($("#latitude").val() === "") {
      alert("Latitude Required");
      $("#latitude").focus();
      flag = false;
    } else if ($("#longitude").val() === "") {
      alert("Longitude Required");
      $("#longitude").focus();
      flag = false;
    }

    if (flag === true) {
      var consultingAndVisitingTiming = [];

      for (var i = 0; i < consultingArrayDetailsData.length; i++) {
        var g = {
          time:
            consultingArrayDetailsData[i].consultingTimeFrom +
            "-" +
            consultingArrayDetailsData[i].consultingTimeTo,

          type: "C",
        };

        consultingAndVisitingTiming.push(g);
      }

      for (var i = 0; i < visitingArrayDetailsData.length; i++) {
        var e = {
          time:
            visitingArrayDetailsData[i].visitingTimeFrom +
            "-" +
            visitingArrayDetailsData[i].visitingTimeTo,

          type: "V",
        };
        consultingAndVisitingTiming.push(e);
      }

      var servicedetailsArray = [];

      for (var i = 0; i < servicedetailsData.length; i++) {
        var b = {
          serviceId: servicedetailsData[i].hospitalServiceId,
          time:
            servicedetailsData[i].servicesTimeFrom +
            "-" +
            servicedetailsData[i].servicesTimeTo,
        };

        servicedetailsArray.push(b);
      }

      var specialisationsSelect = [];
      for (var s = 0; s < specialisations.length; s++) {
        if (
          $("#chkSpecialisation" + specialisations[s].specializationId).is(
            ":checked"
          ) === true
        ) {
          var a = specialisations[s].specializationId;

          specialisationsSelect.push(a);
        }
      }

      const apiJson = {
        method: "POST",

        body: JSON.stringify({
          functionName: "updateHospitalDetails",

          hospitalId: $("#hospitalId").val(),

          groupHospitalAdminId: "180904",

          googleAddressPin: $("#googleAddressPin").val(),

          hospitalName: $("#hospitalName").val(),

          hospitalImage: !hospitalImage
            ? hospitalImage
            : hospitalImage.split("/")[2],

          hospitalEmail: $("#hospitalEmail").val(),

          hospitalAddress: $("#hospitalAddress").val(),

          countryId: $("#cmbCountry").val(),

          stateId: $("#stateId").val(),

          cityObj: {
            cityId: $("#cityId").val().split("-")[0],
            cityName: $("#cityId :selected").text(),
          },

          subCityId: $("#cmbSubCity").val(),

          pinCode: $("#pinCode").val(),

          phone: $("#phone").val(),

          primaryEmailIds: $("#phone").val(),

          categoryId: $("#categoryId").val(),

          credentialId: $("#credentialId").val(),

          typeId: $("#typeId").val(),

          classificationIds: $("#classificationIds")
            .val()
            .toString()
            .replace(/[\[\]']/g, ""),

          paymentTypeIds: $("#paymentTypeIds")
            .val()
            .toString()
            .replace(/[\[\]']/g, ""),

          workTimeFilter: $("#workTimeFilter")
            .val()
            .toString()
            .replace(/[\[\]']/g, ""),

          accreditationIds: $("#accreditationIds")
            .val()
            .toString()
            .replace(/[\[\]']/g, ""),

          insuranceIds: $("#insuranceIds")
            .val()
            .toString()
            .replace(/[\[\]']/g, ""),

          hospitalOfficeHours: [
            {
              sundayTo: $("#sh1SunTo").val(),

              sundayFrom: $("#sh1SunFrom").val(),

              mondayTo: $("#sh1MonTo").val(),

              mondayFrom: $("#sh1MonFrom").val(),

              tuesdayTo: $("#sh1TueTo").val(),

              tuesdayFrom: $("#sh1TueFrom").val(),

              wednesdayTo: $("#sh1WedTo").val(),

              wednesdayFrom: $("#sh1WedFrom").val(),

              thursdayTo: $("#sh1ThurTo").val(),

              thursdayFrom: $("#sh1ThurFrom").val(),

              fridayTo: $("#sh1FriTo").val(),

              fridayFrom: $("#sh1FriFrom").val(),

              saturdayTo: $("#sh1SatTo").val(),

              saturdayFrom: $("#sh1SatFrom").val(),
            },
          ],

          specializationIds: specialisationsSelect
            .toString()
            .replace(/[\[\]']/g, ""),

          associationIds: this.state.associationIds.toString(),

          fax: $("#fax").val(),

          secondaryEmailIds: $("#secondaryEmailIds").val(),

          adminDisplayName: $("#adminDisplayName").val(),

          adminUserTitle: $("#adminUserTitle").val(),

          adminUserName: $("#adminUserName").val(),

          adminPassword: $("#adminPassword").val(),

          isAccountExpired: $("#isAccountExpired").val(),

          isAccountLocked: $("#isAccountLocked").val(),

          isPasswordExpired: $("#isPasswordExpired").val(),

          isEHREnabled: $("#isEHREnabled").val(),

          isVideoRecordingAvailable: $("#isVideoRecordingAvailable").val(),

          websiteUrl: $("#websiteUrl").val(),

          rating: "1",

          sortId: "1",

          advertismentId: "1",

          beds: "1",

          isMedicalTourism: $("#isMedicalTourism").val(),

          isRegistered: $("#isRegistered").val(),

          isAcceptOnlineAppointment: $("#isAcceptOnlineAppointment").val(),

          isverified: $("#isverified").val(),

          isShowHospitallogoassitelogo: $(
            "#isShowHospitallogoassitelogo"
          ).val(),

          isShowHospitalMedicineOnly: $("#isShowHospitalMedicineOnly").val(),

          isPrepaidCardEnabled: $("#isPrepaidCardEnabled").val(),

          isVideoEnabled: $("#isVideoEnabled").val(),
          hasPrNoEnabled: $("#isPRNumberEnabled").val(),

          isDoctorInitiatedChat: $("#isDoctorInitiatedChat").val(),

          isCashback: $("#isCashback").val(),

          cashbackPercent: "1",

          hasDiscount: $("#hasDiscount").val(),

          isPromoCode: $("#isPromoCode").val(),

          hasPmWaterMark: $("#hasPmWaterMark").val(),

          hasSuperAdmin: $("#hasSuperAdmin").val(),

          isEnabled: $("#isEnabled").val(),

          superAdminDisplayName: $("#superAdminDisplayName").val(),

          superAdminUserName: $("#superAdminUserName").val(),

          superAdminPassword: $("#superAdminPassword").val(),

          bankAccountName: $("#bankAccountName").val(),

          bankAccountEmail: $("#bankAccountEmail").val(),

          businessName: $("#businessName").val(),

          linkedAccountId: $("#linkedAccountId").val(),

          branchIFSCCode: $("#branchIFSCCode").val(),

          bankAccountNo: $("#bankAccountNo").val(),

          beneficiaryName: $("#beneficiaryName").val(),

          businessTypeId: "1",

          consultingAndVisitingTiming: consultingAndVisitingTiming,

          description: $("#description").val(),

          searchKeywords: $("#searchKeywords").val(),

          officeDetails: $("#officeDetails").val(),

          serviceOffered: $("#serviceOffered").val(),

          latitude: $("#latitude").val(),

          longitude: $("#longitude").val(),

          serviceDetails: servicedetailsArray,

          deactiveDate: $("#deactiveDate").val(),

          activeDate: $("#activeDate").val(),

          registerDate: $("#registrationDate").val(),

          createdOn: $("#txtCreatedOn").val(),

          slug: this.state.slug,

          seoTitle: this.state.seoTitle,

          seoKeywords: this.state.seoKeywords,

          seoDescription: this.state.seoDescription,

          logoImage: "",

          photoImage: null,

          backgroundColour: this.state.backgroundColour,

          foregroundColour: this.state.foregroundColour,

          buttonColour: this.state.buttonColour,

          fontColour: this.state.fontColour,

          iFrameLink:
            "<html><body><iframe id='we' src='http://www.purplehealth.com/vaas/doctorsList?id=11767' style='width:250px;height:300px;' scrolling='no'></iframe></body></html>",

          windowLink:
            "window.open('http://www.purplehealth.com/vaas/doctorsList?id=11767', '_blank', 'toolbar=0,location=0,menubar=0,width='300',height='300'')",
        }),
      };

      // console.log(apiJson.body, "save");

      apiCalling(apiJson).then((data) => {
        if (data.success === "1") {
          $("#hospitalId").val() === ""
            ? alert("Hospital Details Saved Successfully")
            : alert("Hospital Details Updated Successfully");
          const hospApi = {
            method: "POST",
            body: JSON.stringify({
              functionName: "hospitalDefaultDataSave",
              hospitalId: data.hospitalId,
            }),
          };
          apiCalling(hospApi);
          $("#txtCancel").click();
        } else {
          alert("error");
        }
      });
    }
  };

  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
          <section>
            <Header /> <div className="loader"></div>
          </section>
        </Suspense>

        <div className="container mb-5">
          <div>
            <ul className="breadcrum-adminMangmnt">
              <li>
                <a href="/admin/dashboard">Dashboard</a>
              </li>

              <li>
                <a href="/adminManagement/hospitalManagementList/hospitalManagementList">
                  Hospital Management
                </a>
              </li>

              <li>
                <a href="" className="current">
                  Hospital Details
                </a>
              </li>
            </ul>
          </div>

          <div className="smssetng-btn mb-4 mt-4">
            <input
              type="submit"
              value={this.state.hospitalId > 0 ? "Update" : "Save"}
              className="smssetng-svebtn"
              onClick={() => this.saveHospital()}
            />

            <Link
              to={{
                pathname:
                  "/adminManagement/hospitalManagementList/hospitalManagementList",
              }}
            >
              <input
                type="submit"
                id="txtCancel"
                value="Cancel"
                className="smssetng-cancelbtn"
              />
            </Link>
          </div>

          <div className="addHspPage-Wrapper">
            <Tabs>
              <TabList>
                <Tab>Hospital Details</Tab>

                {/* <Tab>Seo</Tab> */}

                {/* <Tab>Link</Tab> */}

                {/* <Tab>Photo</Tab>

                  <Tab>Logo Upload</Tab> */}
              </TabList>

              <div className="doctr-wraper-border doctor_wrap">
                <TabPanel>
                  {/* for hospital Edit */}

                  {(this.state.hospitalDataArray.length > 0 ||
                    this.state.hospitalId === "") && (
                      <HospitalInfomation
                        ref="hospitalInformationElement"
                        getCountryList={this.getCountryList}
                        hospitalData={this.state}
                        setFileSignature={(fileObj) => {
                          this.setState({ fileSignature: fileObj });
                        }}
                        changeHandler={this.changeHandler}
                        changeHandlerMulti={this.changeHandlerMulti}
                      />
                    )}

                  {(this.state.hospitalDataArray.length > 0 ||
                    this.state.hospitalId === "") && (
                      <GoogleMap
                        longitude={this.state.longitude}
                        latitude={this.state.latitude}
                        googleAddressPin={this.state.googleAddressPin}
                        changeHandler={this.changeHandler}
                        setGoogleAddressPin={(googleAddressPin) => {
                          this.setState({ googleAddressPin });
                        }}
                        selectGoogleAddressPin={(googleAddressPin, lat, lng) => {
                          this.setState({
                            googleAddressPin: googleAddressPin,
                            latitude: lat,
                            longitude: lng,
                          });
                        }}
                        onMarkerDragEnd={(lat, lng) => {
                          this.setState({ latitude: lat, longitude: lng });
                        }}
                      />
                    )}

                  {(this.state.HospitalworkDetailsArray.length > 0 ||
                    this.state.hospitalId === "") && (
                      <HospitalworkDetails
                        HospitalworkData={this.state.HospitalworkDetailsArray}
                        accreditationIdEdit={this.state.accreditationIdEdit}
                        insuranceIdEdit={this.state.insuranceIdEdit}
                      />
                    )}

                  {((this.state.specializationIdEdit.length > 0 &&
                    this.state.specializationData.length > 0) ||
                    this.state.hospitalId === "") && (
                      <HospitalSpecialisation
                        ref="specialisationElement"
                        onChange={(value) => {
                          this.setState({ specializationData: value });
                        }}
                        specializationData={this.state.specializationData}
                        specializationIdEdit={this.state.specializationIdEdit}
                      />
                    )}

                  {(this.state.hospitalDataArray.length > 0 ||
                    this.state.hospitalId === "") && (
                      <HospitalAdmindetails
                        hospitalData1={this.state.hospitalDataArray}
                        hospitalState={this.state}
                        onChange={(value) => {
                          this.setState({ associationIds: value });
                        }}
                      />
                    )}

                  {(this.state.hospitalDataArray.length > 0 ||
                    this.state.hospitalId === "") && (
                      <SuperAdmin hospitalData1={this.state.hospitalDataArray} />
                    )}

                  {(this.state.hospitalDataArray.length > 0 ||
                    this.state.hospitalId === "") && (
                      <BankAccountdetails
                        hospitalData1={this.state.hospitalDataArray}
                      />
                    )}

                  {(this.state.hospitalConsultingTimeEdit.length > 0 ||
                    this.state.hospitalId === "") && (
                      <ConsultingTime
                        setHospitalConsultingTimeEdit={(
                          hospitalConsultingTimeEdit
                        ) => {
                          this.setState({ hospitalConsultingTimeEdit });
                        }}
                        hospitalConsultingTimeEdit={
                          this.state.hospitalConsultingTimeEdit
                        }
                      />
                    )}

                  {(this.state.hospitalVisitingTimeEdit.length > 0 ||
                    this.state.hospitalId === "") && (
                      <VisitingTime
                        setHospitalVisitingTimeEdit={(
                          hospitalVisitingTimeEdit
                        ) => {
                          this.setState({ hospitalVisitingTimeEdit });
                        }}
                        hospitalVisitingTimeEdit={
                          this.state.hospitalVisitingTimeEdit
                        }
                      />
                    )}

                  {(this.state.hospitalDataArray.length > 0 ||
                    this.state.hospitalId === "") && (
                      <Servicedetails
                        ref="servicedetailsElement"
                        hospitalData1={this.state.hospitalDataArray}
                        hospitalServiceDetailsEdit={
                          this.state.hospitalServiceDetailsArray
                        }
                        setHospitalServiceDetailsEdit={(
                          hospitalServiceDetailsArray
                        ) => {
                          this.setState({ hospitalServiceDetailsArray });
                        }}
                        servicesData={this.state.servicesData}
                      />
                    )}

                  <div className="smssetng-btn text-left pt-2">
                    <input
                      type="submit"
                      value={this.state.hospitalId > 0 ? "Update" : "Save"}
                      className="smssetng-svebtn"
                      onClick={() => this.saveHospital()}
                    />
                    <Link
                      to={{
                        pathname:
                          "/adminManagement/hospitalManagementList/hospitalManagementList",
                      }}
                    >
                      <input
                        type="submit"
                        value="Cancel"
                        id="txtCancel"
                        className="smssetng-cancelbtn"
                      />
                    </Link>
                  </div>
                </TabPanel>
              </div>

              {/* <TabPanel>

                   <Seo />

              </TabPanel> */}

              {/* <TabPanel>

                <LinkHsp />

              </TabPanel> */}

              {/* <TabPanel>

                <Photo />

              </TabPanel>

              <TabPanel>

                  <LogoUpload />

              </TabPanel> */}
            </Tabs>
          </div>
        </div>

        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
