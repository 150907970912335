import React, { Component, Suspense, useState } from "react";
import $ from "jquery";
import Header from "../../Reliance/header"
import Footer from "../../Reliance/footer"
import moment from "moment";
import { apiCalling } from "../../apiService";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Logoimg from "../../image/icons/image-preview.png";
import AWS from "aws-sdk";
import { uploadToS3 } from "../../UploadFile";
var s3;
var imageFile;
export default class Addbanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            txtTitle: '',
            txtName: '',
            hospitalId: '',
            txtDescription: '',
            txtRank: '',
            // s3bucketAccessKey: "",
            // AWSAccessKeyId: "",
            hospitalData: [],
            region: "us-east-1",
            bucket: "",
            bucketFolder: "",
            hospitalImagesS3: "",
            fileNameOnly: "",
            fileNameWithPath: "",
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const { value, name } = event.target;
        this.setState({
            [name]: value,
        });
    }

    componentDidMount() {

        this.hospitalList();
        this.imageUploadHandler();
        if (this.props.match.params.hospitalbannerId) {
            this.editHospitalBannerResult();
        }
    }

    hospitalList() {

        const getDetails = {
            method: "POST",
            body: JSON.stringify({
                functionName: "getHospitalList"
            }),
        }
        apiCalling(getDetails).then((data) => {
            if (data.success === "1") {
                this.setState({ hospitalData: data.HospitalData });
            }
        });
    }

    editHospitalBannerResult() {

        const approvalData = {
            method: "POST",
            body: JSON.stringify({
                functionName: "hospitalBannerUploadDetails",
                hospitalBannerId: this.props.match.params.hospitalbannerId,
            }),
        };
        $(".loader").show();
        apiCalling(approvalData).then((data) => {
            if (data.success === "1") {
                let bannerFromDate = moment(data.hospitalBannerData[0].fromDate, "DD-MM-YYYY").format("YYYY-MM-DD");
                let bannerToDate = moment(data.hospitalBannerData[0].toDate, "DD-MM-YYYY").format("YYYY-MM-DD");
                this.setState({
                    txtName: data.hospitalBannerData[0].bannerName,
                    hospitalId: data.hospitalBannerData[0].hospitalId,
                    txtTitle: data.hospitalBannerData[0].title,
                    txtDescription: data.hospitalBannerData[0].description,
                    txtRank: data.hospitalBannerData[0].rank,
                    fileNameWithPath: data.hospitalBannerData[0].imageFullpath,
                    fileNameOnly: data.hospitalBannerData[0].imageNameOnly,
                });
                $("#bannerFromDate").val(bannerFromDate)
                $("#bannerToDate").val(bannerToDate)
                if (data.hospitalBannerData[0].isappbanner === true) {
                    $("#chkIsApp").prop('checked', true);
                }
                else {
                    $("#chkIsApp").prop('checked', false);
                }
                if (data.hospitalBannerData[0].isActive === true) {
                    $("#chkIsActive").prop('checked', true);
                }
                else {
                    $("#chkIsActive").prop('checked', false);
                }
            }
            $(".loader").hide();
        });
    }


    validateForm() {

        let txtHospital = $("#txtHospital").val();

        if (this.state.txtTitle === "") {
            alert("Enter Title ");
            $("#txtTitle").focus();
        }
        else if (this.state.txtName === "") {
            alert("Enter Name ");
            $("#txtName").focus();
            return false;
        }
        else if (txtHospital === "") {
            alert("Select Hospital ");
            $("#txtHospital").focus();
            return false;
        }
        else if (this.state.txtDescription === "") {
            alert("Enter Description ");
            $("#txtDescription").focus();
            return false;
        }
        else if (this.state.txtRank === "") {
            alert("Enter Rank  ");
            $("#txtRank").focus();
            return false;
        } else if (this.state.fileNameWithPath === "") {
            alert("Upload Image");
            return false;
        } else {
            return true;
        }
    }

    saveHandle = () => {

        if (this.validateForm()) {

            let txtHospital = $("#txtHospital").val();
            let isAppBanner = ($("#chkIsApp").is(":checked") === true ? '1' : '0')
            let isActive = ($("#chkIsActive").is(":checked") === true ? '1' : '0')
            let fromdate = moment($("#bannerFromDate").val(), "YYYY-MM-DD").format("DD/MM/YYYY");
            let todate = moment($("#bannerToDate").val(), "YYYY-MM-DD").format("DD/MM/YYYY");
            const saveField = {
                method: "POST",
                body: JSON.stringify({
                    functionName: "hospitalBannerUploadAddUpdate",
                    hospitalBannerId: this.props.match.params.hospitalbannerId === "null" ? "" : this.props.match.params.hospitalbannerId,
                    fromdate: fromdate,
                    todate: todate,
                    description: this.state.txtDescription,
                    imagePath: this.state.fileNameOnly,
                    isAppBanner: isAppBanner,
                    isActive: isActive,
                    name: this.state.txtName,
                    rank: this.state.txtRank,
                    title: this.state.txtTitle,
                    hospitalId: txtHospital
                }),
            };
            $(".loader").show();
            apiCalling(saveField).then((data) => {
                $(".loader").hide();
                if (data.success === "1") {
                    let message =
                        this.props.match.params.hospitalbannerId > 0
                            ? "Updated successfully "
                            : "Save successfully";
                    alert(message);
                    this.cancelHandle();
                } else if (data.success === "0") {
                    alert(data.errorMessage);
                }
            });
        }
    };

    imageUploadHandler() {
        $(".loader").show();
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                functionName: "imagePath",
                siteId: "",
            }),
        };
        apiCalling(apiJson).then((data) => {
            if (data.success === "1") {
                $(".loader").hide();
                // this.setState({
                //     s3bucketAccessKey: data.configPathDetails[0].s3bucketAccessKey,
                // });
                // this.setState({
                //     AWSAccessKeyId: data.configPathDetails[0].s3bucketSecretKey,
                // });
                this.setState({
                    bucket: data.configPathDetails[0].PurpleHealthS3bucket,
                });
                this.setState({
                    bucketFolder: data.configPathDetails[0].S3BucketMainFolder,
                });
                this.setState({
                    hospitalImagesS3: data.configPathDetails[0].S3BucketHospitalImages,
                });
            }
        });
    }

    uuidv4 = () => {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
            /[xy]/g,
            function (c) {
                var r = (Math.random() * 16) | 0,
                    v = c == "x" ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            }
        );
    };

    fileChange = (e) => {
        imageFile = e.target.files[0];
        if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
            alert("Add file format with only JPG,JPEG,PNG format");
            return false;
        }
        if (imageFile.size > 3145728) {
            alert("File size must under 3 MB!");
            return false;
        } else {
            var fileObj = e.target.files[0];
            this.setState({ fileNameWithPath: window.URL.createObjectURL(fileObj) });
            this.fileUpload(e.target.files[0]);
        }
    };

    async fileUpload(file) {
        // AWS.config.update({
        //     accessKeyId: this.state.s3bucketAccessKey,
        //     secretAccessKey: this.state.AWSAccessKeyId,
        //     region: this.state.region,
        // });
        // var bucketParams = {
        //     Bucket:
        //         this.state.bucket +
        //         "/" +
        //         this.state.bucketFolder +
        //         "/" +
        //         this.state.hospitalImagesS3,
        // };
        // s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
        // let S3BucketMainFolder = "";
        let fileName = this.uuidv4() + file.name;
        // let fullFilePath = this.state.bucketFolder + fileName;
        imageFile = fileName;
        const s3url = await uploadToS3(file, this.state.bucketFolder + "/" + this.state.hospitalImagesS3, fileName, this.state.bucket)

        if (s3url) {
            this.setState({
                fileNameWithPath: s3url,
                fileNameOnly: fileName,
            });
            return null;
        }
        else { alert("There was an error uploading your photo: ") }
        // s3.upload(
        //     {
        //         Key: fullFilePath,
        //         Body: file,
        //         ACL: "public-read",
        //     },
        //     (err, data) => {
        //         if (err) {
        //             alert("There was an error uploading your photo: ", err.message);
        //         } else if (data) {
        // this.setState({
        //     fileNameWithPath: data.Location,
        //     fileNameOnly: fileName,
        // });
        // return null;
        //         }
        //     }
        // );
    }

    cancelHandle() {
        window.location.href = "/adminManagement/mobileAppdashboard/hospitalbannerdetails";
    }

    srcTypeImage(ev) {
        ev.target.src = Logoimg;
    }

    render() {

        return (
            <div className="purpleWrap bg-clr-wthHead">
                <Suspense fallback={<div></div>}>
                    <section>
                        <Header />{" "}
                        <div className='loader'></div>
                    </section>
                </Suspense>
                <div className="container">
                    <div>
                        <ul className="breadcrum-adminMangmnt">
                            <li><a href="/admin/dashboardAdmin">Dashboard</a></li>
                            <li><a href="/adminDashboard/mobileappdashboard">Hospital Banner Listing</a></li>
                            <li><a href="" className="current">Hospital Details</a></li>
                        </ul>
                    </div>
                    <div className="hspbannerdetlPage-Wrapper addHspPage-Wrapper">
                        <Tabs>
                            <TabList>
                                <Tab>Banner Detail</Tab>
                            </TabList>
                            <TabPanel>
                                <div className="banerdetlwrapperborder">
                                    <div className="addbanerhspOuterDiv">
                                        <div className="bannerdetlhead-hsp">
                                            <h2 className="hspInfoSubhead">Add Hospital Banner</h2>
                                        </div>
                                        <div className="bannerhspcontent-wraper">
                                            <div className="addbannerhsp-contentdiv  row mb-2">
                                                <label className="col-md-2 col-4">Title<span className="hspifoMantory">*</span></label>
                                                <input type="text" id="txtTitle" name="txtTitle" value={this.state.txtTitle} className="addbanerhspfieldinput  col-md-3 col-8" placeholder="Title" onChange={this.handleChange} />
                                            </div>
                                            <div className="addbannerhsp-contentdiv row mb-2">
                                                <label className="col-md-2 col-4">Name<span className="hspifoMantory">*</span></label>
                                                <input type="text" id="txtName" name="txtName" value={this.state.txtName} className="addbanerhspfieldinput col-md-3 col-8" placeholder="Name" onChange={this.handleChange} />
                                            </div>

                                            <div className="addbannerhsp-contentdiv row mb-2">
                                                <label className="col-md-2 col-4">Hospital<span className="hspifoMantory">*</span></label>
                                                <select name="txtHospital" id="txtHospital" className="addbanerhspfieldinput col-md-3 col-8" onChange={this.handleChange}>
                                                    <option value="">Hospital</option>
                                                    {this.state.hospitalData.map((obj) =>
                                                        obj.hospitalId === this.state.hospitalId ? (
                                                            <option key={obj.hospitalId} value={obj.hospitalId} selected>
                                                                {obj.hospitalName}
                                                            </option>
                                                        ) : (
                                                            <option value={obj.hospitalId}>{obj.hospitalName}</option>
                                                        )
                                                    )}
                                                </select>
                                            </div>
                                            <div className="addbannerhsp-contentdiv addbannerhsp-contentdiv-upload  row mb-2">
                                                <label className="col-md-2 col-4">Image<span className="hspifoMantory">*</span></label>
                                                <div className="addbanr-hspimgaeuploadDiv col-md-3 col-8 px-0">
                                                    <div className="hspaddbanr-btnupload hspinfo-btnupload">
                                                        <span>Upload</span>
                                                        <input type="file" name="fileText" id="fileText" onChange={this.fileChange} className="hspinfoInputfieldupload" />
                                                    </div>
                                                </div>
                                                <div>

                                                    {this.state.fileNameWithPath.length > 0 ?
                                                        <div className="fileUpload-img fileUpload-imgreptop uplod-imge-inlinebaner">
                                                            <img
                                                                id="fileNameWithPath"
                                                                onError={this.srcTypeImage}
                                                                src={this.state.fileNameWithPath}
                                                                id="blogImage"
                                                                width="50"
                                                            ></img>
                                                        </div>
                                                        : ""}
                                                </div>
                                            </div>
                                            <div className="addbannerhsp-contentdiv addbannerhsp-contentdiv-clear row mb-2">
                                                <label className="col-md-2 col-4">Description<span className="hspifoMantory">*</span></label>
                                                <input type="text" id="txtDescription" name="txtDescription" value={this.state.txtDescription} className="addbanerhspfieldinput col-md-3 col-8" placeholder="Description" onChange={this.handleChange} />
                                            </div>
                                            <div className="addbannerhsp-contentdiv row mb-2">
                                                <label className="col-md-2 col-4">Rank<span className="hspifoMantory">*</span></label>
                                                <input type="text" id="txtRank" name="txtRank" value={this.state.txtRank} className="addbanerhspfieldinput col-md-3 col-8" placeholder="Rank" onChange={this.handleChange} />
                                            </div>
                                            <div className="addbannerhsp-contentdiv row mb-2">
                                                <label className="col-md-2 col-4">From Date</label>
                                                <input type="date" id="bannerFromDate" name="bannerFromDate" onChange={this.handleChange} className="addbanerhspfieldinput text-uppercase col-md-3 col-8" />
                                            </div>
                                            <div className="addbannerhsp-contentdiv row mb-2">
                                                <label className="col-md-2 col-4">To Date</label>
                                                <input type="date" id="bannerToDate" name="bannerToDate" onChange={this.handleChange} className="addbanerhspfieldinput text-uppercase col-md-3 col-8" />

                                            </div>
                                            <div className="superadminDivBoxcover">
                                                <div className="addbannerhsp-contentdiv addbannerhsp-contentdiv-check row mb-2">
                                                    <div className="doctrcheckboxDivhsp hspbnradddiv-mob col-md-2 col-4 d-flex align-items-center">
                                                        <input type="checkbox" name="chkIsApp " id="chkIsApp" className="align-middle mr-2" />
                                                        <label className="mb-0">Is App</label>
                                                    </div>
                                                    <div className="doctrcheckboxDivhsp hspbnradddiv-mob col-md-3 col-8 d-flex align-items-center">
                                                        <input type="checkbox" name="chkIsActive" className="align-middle mr-2" id="chkIsActive" />
                                                        <label className="mb-0">Is Active</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="smssetng-btn addrep-btn">
                                                <input type="submit" value={this.props.match.params.hospitalbannerId > 0 ? "Update" : "Save"} className="smssetng-svebtn" onClick={this.saveHandle} />
                                                <input type="submit" value="Cancel" className="smssetng-cancelbtn" onClick={() => this.cancelHandle()} />
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </TabPanel>
                        </Tabs>
                    </div>

                </div>
                <Suspense fallback={<div></div>}>
                    <section>
                        <Footer />
                    </section>
                </Suspense>
            </div>
        );
    }
}
