import React, { Component, Suspense, useState } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import moment from "moment";
import AWS from "aws-sdk";
import { apiCalling } from "../apiService";
import { uploadToS3 } from "../UploadFile";
import { Link } from "react-router-dom";
import { addDays } from "date-fns";
import HealthDocumentNew from "./health-document-new";
import HealthDocumentList from "./health-document-list";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));

var s3;
let Example = () => {
  const [startDate, setStartDate] = useState(new Date());

  return <DatePicker includeDates={[]} />;
};
export default class Bookappointment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      appDocId: "",
      appHosId: "",
      testState: "",
      appType: window.sessionStorage.getItem("flagOption"),
      reasonForVisit: "",
      seeingDr: "me",
      sFirstName: "",
      sLastName: "",
      sGender: "",
      sDOB: "",
      userDOB: "",
      maxDate: "",
      appDate: "",
      clicked: false,
      appMessage: "",
      appointmentSlot: [],
      selectedDate: "",
      startDate: "",
      selTime: "- - Select Slot - -",
      file: [],
      fileName: "",
      dateofBirth: "",
      gender: "",
      // s3bucketAccessKey: "",
      // AWSAccessKeyId: "",
      region: "us-east-1",
      bucket: "",
      bucketFolder: "",
      genderM: "",
      doB: "",
      image: false,
      healthRecords: [],
      flagForNewUser: false,
      videoAppointmentTimeInterval: "",
      inpersonAppointmentTimeInterval: "",
      callADoctorTimeInterval: "",
      videoChatPrice: "",
      // startDate1: null,
    };
    // alert(window.sessionStorage.getItem("flagOption"))
    if (window.sessionStorage.getItem("bySlot") === "Yes") {
      window.sessionStorage.setItem("AppFlag", "2");
      window.sessionStorage.getItem("selDate");
      window.sessionStorage.getItem("selTime");
    } else {
      window.sessionStorage.setItem("AppFlag", "1");
    }
    if (window.sessionStorage.getItem("doctor_details") === "1") {
      window.sessionStorage.setItem("doctor_details", "0");
    }
    // }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputChangeSlot = this.handleInputChangeSlot.bind(this);
    this.handleInputChangeType = this.handleInputChangeType.bind(this);
    this.fileChange = this.fileChange.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this.awsCredentials();
    // alert(window.sessionStorage.getItem("appHosId"))
  }

  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleInputChangeType = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({ startDate: "", appointmentSlot: [] });

    if (value === "c") {
      $("#fileUploadHideAndShow").show();
      this.setState({ selTime: "- - Select Slot - -" });
      this.showDateInCalender("c");
      //$("#appDate").val("");
    }
    if (value === "p") {
      $("#fileUploadHideAndShow").hide();
      this.setState({ selTime: "- - Select Slot - -" });
      this.showDateInCalender("p");
      // $("#appDate").val("");
    }
    if (value === "v") {
      $("#fileUploadHideAndShow").show();
      this.setState({ selTime: "- - Select Slot - -" });
      this.showDateInCalender("v");
      // $("#appDate").val("");
    }
  };
  getMedicalRecord() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "healthRecords/patient/showDetails",
        userId: window.sessionStorage.getItem("userId"),
        max: "0",
        offset: "10",
        fromInput: "",
        otp: "",
        mobileNumber: "",
      }),
    };
    // console.log(apiJson.body)
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        //alert()
        this.setState({
          healthRecords: data.healthRecords,
        });
      }
    });
  }
  handleInputChangeSlot = (event) => {
    if (!window.sessionStorage.getItem("isLogin")) {
      this.loginValidations();
    } else {
      $(".loader").show();
      let newAppDate = $("#selectDate").val();
      const apiJson = {
        method: "POST",
        body: JSON.stringify({
          functionName: "appointmentSlot",
          appoinmentType: this.state.appType,
          doctorId: window.sessionStorage.getItem("appDocId"),
          hospitalId: window.sessionStorage.getItem("appHosId"),
          appointmentDate: newAppDate,
        }),
      };
      // console.log(
      //   "🚀 ~ file: bookappointment.jsx:163 ~ apiJson:",
      //   apiJson.body
      // );
      //console.log(apiJson.body)
      apiCalling(apiJson).then((data) => {
        //console.log("appointmentSlot", "data.errormessage", data)
        if (data.isExist === "true") {
          this.setState({ selectedDate: $("#selectDate").val() });
          this.setState({ appointmentSlot: data.slotValUtc });
        } else {
          $("#alertPopup").show();
          $("#alertPopupTitle").text(
            "Doctor is not available at selected date."
          );
          $("#alertPopupSubTitle").text("Please select another time slot.");
          this.setState({ appointmentSlot: [] });
          $("#appDate").val("");
          this.setState({ startDate: "" });
        }
        $(".loader").hide();
      });
    }
  };

  loginValidations() {
    if (!window.sessionStorage.getItem("isLogin")) {
      $("#ForgotPasswordWrap").hide();
      $("#SignupWrap").hide();
      $("#signInWrapDiv").show();
    }
  }

  awsCredentials() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "imagePath",
        siteId: window.sessionStorage.getItem("siteId"),
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        // this.setState({
        //   s3bucketAccessKey: data.configPathDetails[0].s3bucketAccessKey,
        // });
        // this.setState({
        //   AWSAccessKeyId: data.configPathDetails[0].s3bucketSecretKey,
        // });

        this.setState({
          bucket: data.configPathDetails[0].s3BucketReliance,
        });
        this.setState({
          bucketFolder: data.configPathDetails[0].S3BucketMainFolder,
        });
      }
    });
  }
  getPatientProfileDetails() {
    const userAPI = {
      method: "POST",
      body: JSON.stringify({
        functionName: "userDetails",
        siteId: "",
        userId: window.sessionStorage.getItem("userId"),
      }),
    };
    apiCalling(userAPI).then((data) => {
      if (data.success === "1") {
        //console.log("userDetails", "data.errormessage", data)
        if (
          data.userData[0].gender === "" ||
          data.userData[0].dob === "00/00/0000" ||
          data.userData[0].dob === "01/01/1900"
        ) {
          $("#dateGender").show();
          this.setState({ flagForNewUser: true });
        } else {
          $("#dateGender").hide();
        }
        $("#patientGender").val(data.userData[0].gender);
        if (data.userData[0].dob) {
          let dob = moment(data.userData[0].dob, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          );
          this.setState({ dateofBirth: dob });
        }

        this.setState({ gender: data.userData[0].gender });
      }
    });
  }
  componentWillMount() {
    // this.checkService();
    //this.showDateInCalender(window.sessionStorage.getItem("flagOption"));
    this.showDateInCalender(window.sessionStorage.getItem("flagOption"));
    this.getPatientProfileDetails();
  }
  componentWillUpdate() {
    //this.showDateInCalender(window.sessionStorage.getItem("flagOption"));
  }

  checkService() {
    $(".loader").show();
    window.sessionStorage.setItem("b_discountPercentage", "");
    window.sessionStorage.setItem("b_promoCode", "");
    const doctorDetails = {
      method: "POST",
      body: JSON.stringify({
        functionName: "hospitalService",
        siteId: window.sessionStorage.getItem("siteId"),
        doctorId: window.sessionStorage.getItem("appDocId"),
        hospitalId: window.sessionStorage.getItem("appHosId"),
      }),
    };
    //console.log(doctorDetails.body);
    apiCalling(doctorDetails).then((data) => {
      // console.log("pppppppppp9090", data);

      $(".loader").hide();
      if (data.success === "1") {
        this.setState({
          videoAppointmentTimeInterval: data.videoAppointmentTimeInterval,
          inpersonAppointmentTimeInterval: data.inpersonAppointmentTimeInterval,
          callADoctorTimeInterval: data.callADoctorTimeInterval,
          videoChatPrice: data.priceData[0].videoChatPrice,
        });
        // eslint-disable-next-line array-callback-return
        window.sessionStorage.setItem("razorKey", data.razorKey);
        window.sessionStorage.setItem("razorkeysecret", data.razorkeysecret);
        data.result.map((sp) => {
          //alert(sp.serviceName+"-"+sp.status)
          if (sp.serviceName === "Call a Doctor" && sp.status === 1) {
            $("#audioDiv").show();
          }
          if (sp.serviceName === "Video Chat" && sp.status === 1) {
            $("#videoDiv").show();
          }
          if (sp.serviceName === "Book Appointment" && sp.status === 1) {
            $("#inpersonDiv").show();
          }
        });

        window.sessionStorage.setItem(
          "promoCodeData",
          JSON.stringify(data.promoCodeData)
        );
      } else {
        this.checkService();
      }
    });
  }

  componentDidMount() {
    $("#audioDiv").hide();
    $("#inpersonDiv").hide();
    $("#videoDiv").hide();
    this.getMedicalRecord();
    this.getPatientProfileDetails();
    this.checkService();
    this.showDateInCalender(window.sessionStorage.getItem("flagOption"));

    $("#fileUploadHideAndShow").hide();
    let self = this;
    self.awsCredentials();

    if (window.sessionStorage.getItem("AppFlag") === "2") {
      self.setState({ selTime: window.sessionStorage.getItem("selTime") });
      let mDayName = moment(
        window.sessionStorage.getItem("selDate"),
        "DD/MM/YYYY"
      ).format("MM/DD/YYYY");
      self.setState({ startDate: new Date(mDayName) });
      $("#selectDate").val(window.sessionStorage.getItem("selDate"));
    }

    $("#radioMe").prop("checked", true);

    $("#bookAppButton").click(function () {
      var upData = "";
      var uploadSet = [];
      let countInput = 25; //$("#countInput").val();
      //alert(countInput)
      if (countInput != 0) {
        for (var i = 1; i <= countInput; i++) {
          var aa = $("#docFileName" + i).val();
          if (typeof aa !== "undefined") {
            //alert(i)

            uploadSet.push({
              //fileName: $("#docFileName" + i).val(),
              fileName: $("#fileName" + i).val(),
              documentId: $("#recordId" + i).val(),
              documentName: $("#docFileName" + i).val(),
              //documentName: $("#fileName" + i).val(),
              documentDescription: "",
              documentDate: "2021-01-17 15:38:52",
            });
          }
        }
      }
      // console.log(uploadSet)

      //-------------------------------
      if (!window.sessionStorage.getItem("isLogin")) {
        self.loginValidations();
      } else {
        const appType = self.state.appType;
        const appDate = $("#selectDate").val();
        let appMessage = "";
        let newAppDate = "";
        if (appDate) {
          newAppDate = $("#selectDate").val();
        }

        const appTime = $("#newSlot").val();
        const reasonForVisit = self.state.reasonForVisit;
        const seeingDr = self.state.seeingDr;
        // let mDayName = moment(
        //   window.sessionStorage.getItem("selDate"),
        //   "DD/MM/YYYY"
        // ).format("MM/DD/YYYY");

        let newdob = moment($("#dateofBirth").val(), "YYYY-MM-DD").format(
          "DD/MM/YYYY"
        );
        // alert(newdob);
        let dateofBirth;
        let patientGender;

        if (self.state.flagForNewUser === true) {
          dateofBirth = newdob;
          patientGender = $("#patientGender").val();
        } else {
          dateofBirth = "";
          patientGender = "";
        }
        //const userDOB = changeDateFormat(self.state.userDOB);
        const sFirstName = "";
        const sLastName = "";
        // const sGender = "";
        // const sDOB = "";

        // alert(self.state.startDate)
        if (!self.state.startDate) {
          appMessage = "Please select your appointment date ";
          $("#appDate").focus();
        } else if (
          appTime === "" ||
          appTime === null ||
          appTime === "- - Select Slot - -"
        ) {
          appMessage = "Please select your appointment time ";
          $("#newSlot").focus();
        }
        //  else if( $("#dateofBirth").val()===""){ appMessage = "Please select date of birth ";
        //  $("#dateofBirth").focus();
        // }
        //  else if( $("#patientGender").val()===""){ appMessage = "Please select your genter ";
        //  $("#patientGender").focus();
        // }

        //  else if (!reasonForVisit) {
        //   appMessage = "Please enter reason for appointment";
        //   $("#reasonForVisit").focus();
        // }
        else if (
          (self.state.flagForNewUser === true &&
            $("#dateofBirth").val() === "") ||
          $("#dateofBirth").val() === "null"
        ) {
          //alert( $("#dateofBirth").val())
          appMessage = "Please select your date of birth ";
          $("#dateofBirth").focus();
          $("#patientGender").val();
        } else if (self.state.flagForNewUser === true && !patientGender) {
          appMessage = "Please select your gender ";
          $("#patientGender").focus();
        }
        if (appMessage === "") {
          const apiJson = {
            method: "POST",
            body: JSON.stringify({
              functionName: "appointmentExistCheck",
              userId: window.sessionStorage.getItem("userId"),
              siteId: window.sessionStorage.getItem("siteId"),
              doctorId: window.sessionStorage.getItem("appDocId"),
              hospitalId: window.sessionStorage.getItem("appHosId"),
              appointmentType: appType,
              appointmentDate: newAppDate,
              appointmentTime: appTime,
            }),
          };
          apiCalling(apiJson).then((data) => {
            if (data.success === "1") {
              const apiJson = {
                method: "POST",
                body: JSON.stringify({
                  functionName: "createAppointment",
                  userId: window.sessionStorage.getItem("userId"),
                  siteId: window.sessionStorage.getItem("siteId"),
                  doctorId: window.sessionStorage.getItem("appDocId"),
                  hospitalId: window.sessionStorage.getItem("appHosId"),
                  appointmentType: appType,
                  appointmentDateTime: new Date(
                    moment(appDate, "DD/MM/YYYY").format("YYYY-MM-DD") +
                    " " +
                    moment(appTime, ["h:mm A"]).format("kk:mm:ss")
                  ).getTime(),
                  appointmentDate: newAppDate,
                  appointmentTime: appTime,
                  reasonForVisit: reasonForVisit,
                  appointmentPerson: "",
                  newUserGender: patientGender,
                  newUserDOB: dateofBirth,
                  promoCode: "",
                  promoCodeAmount: "",
                  discountPercentage: "",
                  from: "",
                  firstName: sFirstName,
                  //gender: sGender,
                  lastName: sLastName,
                  //newUserDOB: sDOB,
                  //newUserGender: sGender,
                  attachments: self.state.fileName,
                  includeDates: [],
                  attachmentData: uploadSet,
                }),
              };

              let testHr = 0;
              if (appType === "p") {
                testHr = parseInt(self.state.inpersonAppointmentTimeInterval);
              }
              if (appType === "v") {
                testHr = parseInt(self.state.videoAppointmentTimeInterval);
              }
              if (appType === "c") {
                testHr = parseInt(self.state.callADoctorTimeInterval);
              }
              let appointmentDate = newAppDate + " " + appTime;
              let newAppDatetest = moment(
                appointmentDate,
                "DD/MM/YYYY h:mm A"
              ).format("YYYY-MM-DD hh:mm A");

              var todateNow = new Date();
              var todateNow = moment(todateNow)
                .add(testHr, "hours")
                .format("YYYY-MM-DD hh:mm A");

              var td = new Date(todateNow);
              var nd = new Date(newAppDatetest);

              if (td > nd) {
                alert(
                  "You can only book an appointment after " + testHr + " hours"
                );
              } else {
                // console.log("data",self.state.videoChatPrice);
                window.sessionStorage.setItem("appArray", apiJson.body);
                window.sessionStorage.setItem(
                  "videocost",
                  self.state.videoChatPrice
                );
                window.location.href = "../patient/bookappointmentsuccess";
              }

              //+ appData.body;
            } else {
              alert("Doctor already have an another appoinment");
            }
          });
          self.setState({
            clicked: true,
          });
        } else {
          alert(appMessage);
        }
      }
    });
    let today = new Date();
    var month = ("0" + (today.getMonth() + 1)).slice(-2);
    var date = ("0" + today.getDate()).slice(-2);
    self.state.maxDate = today.getFullYear() + "-" + month + "-" + date;
    //self.setState({ appType: window.sessionStorage.getItem("flagOption") });

    if (self.state.appType === "v") {
      $(".radioVideo").prop("checked", true);
      $("#fileUploadHideAndShow").show();
    }
    if (self.state.appType === "p") {
      $(".radioPerson").prop("checked", true);
      $("#fileUploadHideAndShow").hide();
    }
    if (self.state.appType === "c") {
      $(".radioCall").prop("checked", true);
      $("#fileUploadHideAndShow").show();
    }
    $("#divSomeoneElse").hide();
    $(".radioSomeoneelse").click(function () {
      $("#divSomeoneElse").show();
      $("#divSomeoneMe").hide();
      $(".radioSomeoneelse").prop("checked", true);
    });
    $(".radioMe").click(function () {
      $("#divSomeoneElse").hide();
      $("#divSomeoneMe").show();
    });
    $("#book-warnalert").hide();

    $(".radio-item").click(function () {
      $(".radio-item").removeClass("radio-item-active");
      $(this).addClass("radio-item-active");
    });

    $("#file1,#remfile1,#file2,#remfile2,#file3,#remfile3").hide();
    $("#attachmedreport").click(function () {
      if ($("#file1").is(":hidden")) {
        $("#file1").show();
        $("#file1").click();
        $("#remfile1").show();
      } else if ($("#file2").is(":hidden")) {
        $("#file2").show();
        $("#file2").click();
        $("#remfile2").show();
      } else if ($("#file3").is(":hidden")) {
        $("#file3").show();
        $("#file3").click();
        $("#remfile3").show();
      }
    });
  }

  imagepath1 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  alertclose() {
    $("#book-warnalert").hide();
  }
  handleChange = (date) => {
    this.setState({ startDate: date });
    $("#selectDate").val(moment(date).format("DD/MM/YYYY"));
  };

  fileChange(e) {
    $(".loader").show();
    this.setState({ file: e.target.files[0] });
    this.fileUpload(e.target.files[0]);
    //console.log("1111111", e.target.files[0]);
    $("#attachImg").text(e.target.files[0].name);
    $("#aattachImg").attr("href", URL.createObjectURL(e.target.files[0]));
  }

  deleteFile = () => {
    //$("#attachImg").text(this.state.file).remove()
    $("#aattachImg").attr("href", "");
    $("#attachImg").text("");
    this.setState({ fileName: "" });
    $("#fileDeleteButton").hide();
  };

  async fileUpload(file) {
    // AWS.config.update({
    //   accessKeyId: this.state.s3bucketAccessKey,
    //   secretAccessKey: this.state.AWSAccessKeyId,
    //   region: this.state.region,
    // });
    // var bucketParams = {
    //   Bucket: this.state.bucket,
    // };
    // s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    // let S3BucketMainFolder = this.state.bucketFolder + "/attachments";
    let fileName = this.imagepath1() + file.name;
    //// alert(fileName);
    let fullFilePath = this.state.bucketFolder + "/attachments/" + fileName;
    // alert(fullFilePath);
    this.setState({ fileName: fileName })
    const s3url = await uploadToS3(file, this.state.bucketFolder + "/attachments", fileName, this.state.bucket)

    if (s3url) {
      alert("Uploaded Successfully");
      $("#fileDeleteButton").show();
      return null;
    } else {
      alert("There was an error while uploading your photo")
    }
    // s3.upload(
    //   {
    //     Key: fullFilePath,
    //     Body: file,
    //     ACL: "public-read",
    //   },
    //   (err, data) => {
    //     $(".loader").hide();
    //     if (err) {
    //       alert("There was an error uploading your photo: ", err.message);
    //     } else if (data) {
    //       alert("Uploaded Successfully", data.message);
    //       $("#fileDeleteButton").show();
    //       return null;
    //     }
    //   }
    // );
  }

  getCalenderData(type) {
    //alert(type)
    if (type === "") {
      type = this.window.sessionStorage.getItem("flagOption");
    }
    let dateset = [];
    $(".loader").show();
    const doctorDetails = {
      method: "POST",
      body: JSON.stringify({
        functionName: "slotAvailableDays",
        siteId: window.sessionStorage.getItem("siteId"),
        doctorId: window.sessionStorage.getItem("appDocId"),
        hospitalId: window.sessionStorage.getItem("appHosId"),
        appoinmentType: type,
      }),
    };
    // alert(type)
    // console.log(doctorDetails.body, "kkkkkkkkkkkkkkkkkkkk");
    apiCalling(doctorDetails).then((data) => {
      // console.log("slotAvailableDays", "data.errormessage", data);
      $(".loader").hide();
      if (data.availableSlot) {
        data.availableSlot.map((data) => {
          dateset.push(new Date(data));
        });
        this.setState({ includeDates: dateset });
      } else {
        this.setState({ includeDates: [] });
      }
    });
  }

  showDateInCalender(type) {
    this.getCalenderData(type);
  }

  render() {
    return (
      <div class="purpleWrap">
        <Helmet>
          <title>{"Book An Appointment"}</title>
          <meta name="description" content="Book An Appointment" />
          {/* <meta name="Keywords" content="Cost of Dental Implants | Teeth Cleaning | Grinding teeth" /> */}
        </Helmet>
        <Suspense fallback={<div class="loader"></div>}>
          <section>
            <Header />
            <div class="container">
              <div class="loader2"></div>
              <div class="main-wrapper-smwidth main-wrapper-padding">
                <div class="form-section">
                  <div class="alert warnalert alert-danger" id="book-warnalert">
                    {this.state.appMessage}
                    <button
                      type="button"
                      class="close"
                      onClick={this.alertclose}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="row">
                    <div class="col-md-8 row">
                      <div class="col-sm-12 form-head pl-4">
                        Request Appointment
                      </div>
                      <div class="row-margin col-sm-12">
                        <label class="col-sm-12 label-text">
                          Type of Appointment{" "}
                          <span className="star-red">*</span>
                        </label>
                        <div class="col-sm-12 ">
                          <div
                            class="form-check form-check-inline radio-item"
                            id="inpersonDiv"
                          >
                            <input
                              class="form-check-input radioPerson"
                              type="radio"
                              id="radioPerson"
                              name="appType"
                              value="p"
                              onChange={this.handleInputChangeType}
                            />
                            <label class="form-check-label " for="radioPerson">
                              In Person
                            </label>
                          </div>
                          <div
                            class="form-check form-check-inline radio-item"
                            id="videoDiv"
                          >
                            <input
                              class="form-check-input radioVideo"
                              type="radio"
                              id="radioVideo"
                              value="v"
                              name="appType"
                              onChange={this.handleInputChangeType}
                            />
                            <label class="form-check-label" for="radioVideo">
                              Video Chat
                            </label>
                          </div>
                          <div
                            class="form-check form-check-inline radio-item"
                            id="audioDiv"
                          >
                            <input
                              class="form-check-input radioCall"
                              type="radio"
                              id="radioCall"
                              value="c"
                              name="appType"
                              onChange={this.handleInputChangeType}
                            />
                            <label class="form-check-label" for="radioCall">
                              Audio Chat
                            </label>
                          </div>
                        </div>
                      </div>

                      <div class="row-margin col-sm-6">
                        <label class="col-sm-12 label-text">
                          Appointment Date <span className="star-red">*</span>
                        </label>
                        <div class="col-sm-12">
                          <input
                            type="hidden"
                            id="selectDate"
                            name="selectDate"
                          />
                          <DatePicker
                            placeholder="Appointment Date"
                            id="appDate"
                            name="appDate"
                            minDate={new Date()}
                            selected={this.state.startDate}
                            onChange={this.handleChange}
                            onClick={() =>
                              this.getCalenderData(this.state.appType)
                            }
                            dateFormat="dd/MM/yyyy"
                            autoComplete="off"
                            onSelect={this.handleInputChangeSlot}
                            calendarClassName="react-date-picker"
                            // highlightDates={new Date("2020-12-25")}
                            includeDates={this.state.includeDates}
                          // excludeDates={[new Date(), new Date(), 2]}
                          />
                          {/* <Example></Example> */}
                        </div>
                      </div>

                      <div class="row-margin col-sm-6">
                        <label class="col-sm-12 label-text">
                          Appointment Time <span className="star-red">*</span>
                        </label>
                        <div class="col-sm-12">
                          <select
                            id="newSlot"
                            name="mm"
                            class="input-design newSlot selecttime"
                          >
                            <option value={this.state.selTime}>
                              {this.state.selTime}
                            </option>
                            {this.state.appointmentSlot.map((obj, index) => {
                              let selectedDate =
                                this.state.selectedDate + " " + obj;
                              let newSelectedDate = moment(obj).toDate();
                              let currentDate = moment().toDate();
                              let tempDate = moment(new Date(obj)).format(
                                "hh:mm A"
                              );
                              if (
                                newSelectedDate.toDateString() ===
                                currentDate.toDateString()
                              ) {
                                if (newSelectedDate > currentDate) {
                                  return (
                                    <option value={tempDate}>{tempDate}</option>
                                  );
                                }
                              } else {
                                return (
                                  <option value={tempDate}>{tempDate}</option>
                                );
                              }
                            })}
                          </select>
                        </div>
                      </div>

                      <div class="row-margin col-sm-12">
                        <label class="col-sm-12 label-text">
                          Reason For Appointment{" "}
                          {/* <span className="star-red">*</span> */}
                        </label>
                        <div class="col-sm-12">
                          <textarea
                            class="input-design"
                            placeholder="Reason For Appointment"
                            rows="4"
                            name="reasonForVisit"
                            id="reasonForVisit"
                            onChange={this.handleInputChange}
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-lg-12 row" id="dateGender">
                        <div class="row-margin col-sm-6">
                          <label class="col-sm-12 label-text">
                            Date Of Birth <span className="star-red">*</span>
                          </label>
                          <div class="col-sm-12">
                            <input
                              type="date"
                              name="dateofBirth"
                              id="dateofBirth"
                              class="input-design"
                              format="DD/MM/YYYY"
                              onChange={this.handleInputChange}
                              max={this.state.maxDate}
                              value={this.state.dateofBirth}
                            />
                          </div>
                        </div>

                        <div class="row-margin col-sm-6">
                          <label class="col-sm-12 label-text">
                            Gender <span className="star-red">*</span>
                          </label>
                          <div class="col-sm-12 pr-2 pr-md-0">
                            <select
                              type="text"
                              class="input-design"
                              name="gender"
                              id="patientGender"
                              onChange={this.handleInputChange}
                            >
                              <option value="">- - Select Gender - -</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div
                        class="row-margin col-sm-12"
                        id="fileUploadHideAndShow"
                      >
                        <div class="col-sm-12">
                          {/* <div class="fileUpload-medical">
                          <span>Attach Medical Records</span>
                          <input
                            type="button"
                            id="attach"
                            name="attach"
                            class="upload"
                            value="Attach Medical Records"
                          />
                          <input
                            type="file"
                            class="upload"
                            onChange={this.fileChange}
                          />
                        </div>

                        <span
                          class=" fa fa-trash color-maintext ml-5"
                          style={{ display: "none" }}
                          id="fileDeleteButton"
                          onClick={this.deleteFile}
                        ></span>

                        <div className="fileUpload-medicalImg">
                          <span id="aattachImg" target="_blank">
                           
                            <span id="attachImg"></span>
                          </span>
                        </div> */}
                          <div style={{ display: "block" }}>
                            <HealthDocumentNew></HealthDocumentNew>
                          </div>
                        </div>
                      </div>

                      {/* <div class="row-margin col-sm-12">
                  <label class="col-sm-12 label-text">
                    Who will be seeing the Doctor?
                  </label>
                  <div class="col-sm-12">
                    <div class="form-check form-check-inline radio-item">
                      <input
                        class="form-check-input radioMe"
                        type="radio"
                        id="radioMe"
                        name="seeingDr"
                        value="me"
                        onChange={this.handleInputChange}
                        // checked
                      />
                      <label class="form-check-label" for="radioMe">
                        Me
                      </label>
                    </div>
                    <div class="form-check form-check-inline radio-item">
                      <input
                        class="form-check-input radioSomeoneelse"
                        type="radio"
                        name="seeingDr"
                        id="radioSomeoneelse"
                        onChange={this.handleInputChange}
                        value="someone"
                      />
                      <label class="form-check-label" for="radioSomeoneelse">
                        Someone else
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 row pr-0" id="divSomeoneElse">
                  <div class="row-margin col-sm-6">
                    <label class="col-sm-12 label-text">
                      First Name <span className="star-red">*</span>
                    </label>
                    <div class="col-sm-12">
                      <input
                        type="text"
                        class="input-design"
                        id="sFirstName"
                        name="sFirstName"
                        placeholder="First Name"
                        onChange={this.handleInputChange}
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <div class="row-margin col-sm-6">
                    <label class="col-sm-12 label-text">
                      Last Name <span className="star-red">*</span>
                    </label>
                    <div class="col-sm-12">
                      <input
                        type="text"
                        class="input-design"
                        placeholder="Last Name"
                        name="sLastName"
                        id="sLastName"
                        onChange={this.handleInputChange}
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <div class="row-margin col-sm-6">
                    <label class="col-sm-12 label-text">
                      Date Of Birth <span className="star-red">*</span>
                    </label>
                    <div class="col-sm-12">
                      <input
                        type="date"
                        name="sDOB"
                        id="sDOB"
                        class="input-design"
                        onChange={this.handleInputChange}
                        max={this.state.maxDate}
                      />
                    </div>
                  </div>

                  <div class="row-margin col-sm-6">
                    <label class="col-sm-12 label-text">
                      Gender <span className="star-red">*</span>
                    </label>
                    <div class="col-sm-12">
                      <select
                        class="input-design input-box-w-150"
                        name="sGender"
                        id="sGender"
                        onChange={this.handleInputChange}
                      >
                        <option>Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                    </div>
                  </div>
                </div> */}
                    </div>
                    {this.state.appType !== "p" ? (
                      <div
                        class="col-md-4 healthDocMain"
                        style={{ display: "block" }}
                      >
                        <Tabs className="video-appntmnt-list">
                          <TabList>
                            {/* <Tab>
                          <div className="healthDocTab">
                            New Documents
                          </div>
                        </Tab> */}
                            <Tab>
                              <div className="healthDocTab">
                                Medical Records
                              </div>
                            </Tab>
                          </TabList>
                          {/* <TabPanel>
                        <HealthDocumentNew></HealthDocumentNew>
                      </TabPanel> */}
                          <TabPanel>
                            <HealthDocumentList
                              healthRecords={this.state.healthRecords}
                            ></HealthDocumentList>
                          </TabPanel>
                        </Tabs>
                      </div>
                    ) : null}

                    <div className="col-lg-12">
                      <input
                        type="submit"
                        value="Continue"
                        className="formButton"
                        id="bookAppButton"
                      ></input>
                      <Link to="/home/doctor-list-main/0">
                        <input
                          type="button"
                          value="Cancel"
                          className="formButton cancelButton  formButtonBold"
                        ></input>
                      </Link>
                      <input type="hidden" id="countInput" defaultValue="0" />
                      {/* <input type="text" id="fileChangeFlag" defaultValue="0" />  */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
