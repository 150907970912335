import React, { Component, Suspense } from "react";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import { apiCalling } from "../apiService";
import DatePicker from "react-datepicker";
const folderName = window.sessionStorage.getItem("homeName");
const Header = React.lazy(() => import("../" + folderName + "/header"));
const Footer = React.lazy(() => import("../" + folderName + "/footer"));
export default class DoctorPrescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doctorPrescription: [],
      allprescription: [],
      memberId: this.props.match.params.memberId,
      hospitalId: this.props.match.params.hospitalId,
      scheduleId: this.props.match.params.scheduleId,
      listFlag: 0,
    };
  }
  componentDidMount() {
    // console.log("ccccccccc", this.props);
    this.getData();
  }
  viewPreviousAppt() {
    const previousAppts = {
      method: "POST",
      body: JSON.stringify({
        functionName: "patientPrescriptionList",
        scheduledTimeSlotId: "",
        memberId: this.state.memberId,
      }),
    };
    apiCalling(previousAppts).then((data) => {
      // console.log("data", data);
      this.setState({
        allprescription: data.result,
        listFlag: 1,
      });
    });
  }

  getData() {
    $(".loader").show();
    const apiJsonq = {
      method: "POST",
      body: JSON.stringify({
        functionName: "prescriptionList",
        scheduledTimeSlotId:
          this.state.scheduleId === "0"
            ? this.state.scheduleId == ""
            : this.state.scheduleId,
        memberId: this.state.memberId,
        doctorId: window.sessionStorage.getItem("userId"),
        hospitalId: this.state.hospitalId,
      }),
    };
    apiCalling(apiJsonq).then((data) => {
      if (data.success == "1") {
        $(".loader").hide();
        this.setState({
          doctorPrescription: data.prescriptionData,
          listFlag: 0,
        });
      }
    });
  }
  render() {
    return (
      <div class="purpleWrap">
        <div className="loader"></div>
        <Suspense fallback={<div class="loader"></div>}>
          <section>
            <Header />
            <div class="container">
              <div class="main-wrapper1">
                <div class="col-md-12 p-0">
                  <ul class="breadcrumb align-items-center py-1">
                    <li>
                      <a href="/doctor/dashboard">Dashboard</a>
                      <i class="fa fa-chevron-right"></i>
                    </li>
                    <li>
                      <a href="/doctor/videoSchedule">Video and Audio Chat</a>
                      <i class="fa fa-chevron-right"></i>
                    </li>
                    <li>
                      <a
                        href={`/prescriptionAdd-${this.props.match.params.hospitalId}-${this.props.match.params.memberId}-${this.props.match.params.scheduleId}-${this.props.match.params.patientName}`}
                      >
                        Add Prescriptions
                      </a>
                      <i class="fa fa-chevron-right"></i>
                    </li>
                    <li>Prescription List</li>
                    {this.props.location.state ? (
                      <>
                        {this.props.location.state.setPage === "videoChat" ? (
                          <li className="ml-auto">
                            {" "}
                            <button
                              type="button"
                              className="formButton m-0"
                              onClick={() => this.viewPreviousAppt()}
                            >
                              Previous Appointment Prescriptions
                            </button>
                          </li>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
                <div class="form-head mt-4">PRESCRIPTION LIST </div>
                {this.state.listFlag === 0 ? (
                  <>
                    {this.state.doctorPrescription.length > 0 ? (
                      <div class="col-md-12 p-0">
                        {this.state.doctorPrescription.map((data, i) => (
                          <div class="prescription-main">
                            <div class="prescription-maindate">
                              <div class="prescription-date">
                                {data.visit_date}
                              </div>
                            </div>
                            <div class="prescription-Subdiv">
                              <div class="prescription-Submain">
                                <div class="prescription-drName">
                                  Patient Name : &nbsp;
                                  {this.props.match.params.patientName}
                                </div>
                                <div class="prescription-head">
                                  <div class="prescription-subhead">
                                    Medicine
                                  </div>
                                  <div class="prescription-subhead">
                                    Frequency & Dosage
                                  </div>
                                  <div class="prescription-subhead">Intake</div>
                                  <div class="prescription-subhead">
                                    Duration
                                  </div>
                                  <div class="prescription-subhead">
                                    Instruction
                                  </div>
                                </div>
                                {data.prescriptionDetails.map((data1, j) => (
                                  <div class="prescription-det">
                                    <div class="prescription-subdet">
                                      {data1.medicineName}
                                    </div>
                                    <div class="prescription-subdet">
                                      {data1.frequency} {data1.dosage}
                                    </div>
                                    <div class="prescription-subdet">
                                      {data1.intake}
                                    </div>
                                    <div class="prescription-subdet">
                                      {data1.days} {data1.duration}
                                    </div>
                                    <div class="prescription-subdet">
                                      {data1.note}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="error-msg text-center mb-0">
                        {" "}
                        No Records Found{" "}
                      </p>
                    )}
                  </>
                ) : (
                  <>
                    {this.state.allprescription.length > 0 ? (
                      <div class="col-md-12 p-0">
                        {this.state.allprescription.map((data, i) => (
                          <div class="prescription-main">
                            {/* {console.log("datadatadatadatadata", data)} */}
                            <div class="prescription-maindate">
                              <div class="prescription-date">
                                {data.visitDate}
                              </div>
                            </div>
                            <div class="prescription-Subdiv">
                              {data.prescriptionByVisit.map(
                                (prescriptionData, j) => (
                                  <div class="prescription-Submain">
                                    <div class="prescription-drName">
                                      Patient Name : &nbsp;
                                      {this.props.match.params.patientName}
                                    </div>
                                    <div class="prescription-head">
                                      <div class="prescription-subhead">
                                        Medicine
                                      </div>
                                      <div class="prescription-subhead">
                                        Frequency & Dosage
                                      </div>
                                      <div class="prescription-subhead">
                                        Intake
                                      </div>
                                      <div class="prescription-subhead">
                                        Duration
                                      </div>
                                      <div class="prescription-subhead">
                                        Instruction
                                      </div>
                                    </div>
                                    {prescriptionData.prescriptionDetails.map(
                                      (data1, k) => (
                                        <div class="prescription-det">
                                          <div class="prescription-subdet">
                                            {data1.medicineName}
                                          </div>
                                          <div class="prescription-subdet">
                                            {data1.frequency} {data1.dosage}
                                          </div>
                                          <div class="prescription-subdet">
                                            {data1.intake}
                                          </div>
                                          <div class="prescription-subdet">
                                            {data1.days} {data1.duration}
                                          </div>
                                          <div class="prescription-subdet">
                                            {data1.note}
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="error-msg text-center mb-0">
                        {" "}
                        No Records Found{" "}
                      </p>
                    )}
                  </>
                )}

                {/* {this.state.prescriptionList.length > 0 ?
                      <div class="col-md-12 p-0">
                        {this.state.prescriptionList.map((data, i) => (
                          <div class="table-responsive mb-3">
                            <table class="table table-border-main mb-0">
                              <tr style={{ backgroundColor: "#cae5ff" }}>
                                <th>{data.visitDate}</th>
                                {data.prescriptionByVisit.map((data1, j) => (
                                  <div>
                                  <th colSpan="4">{'Doctor Name:- '+data1.displayName}</th>
                              <tr>
                                <th>Medicine</th>
                                <th>Frequency & Dosage</th>
                                <th>Intake</th>
                                <th>Qty</th>
                                <th>Days</th>
                              </tr>
                                  {data1.prescriptionDetails.map((data2, k) => (
                                      <tr>
                                      <td>{data2.medicineName}</td>
                                      <td>{data2.dosage}</td>
                                      <td>{data2.intake}</td>
                                      <td>{data2.quantity}</td>
                                      <td>{data2.duration}</td>
                                    </tr>
                                  ))}
                                  </div>
                                ))}
                              </tr>
                            </table>
                          </div>
                        ))}
    
                      </div>
                      :
                      <p className="error-msg text-center mb-0"> No Records Found </p>
                    } */}

                {/* <div class="col-md-12 p-0">
                    {this.state.prescriptionList.map((data, i) => (
                      <div class="prescription-main">
                        <div class="prescription-maindate">
                          <div class="prescription-date">{data.visitDate}</div>
                        </div>
                        <div class="prescription-Submain">
                          <div class="prescription-drName">Doctor Name</div>
                          <div class="prescription-head">
                            <div class="prescription-subhead">Medicine</div>
                            <div class="prescription-subhead">Frequency & Dosage</div>
                            <div class="prescription-subhead">Intake</div>
                            <div class="prescription-subhead">Qty</div>
                            <div class="prescription-subhead">Days</div>
                          </div>
                          <div class="prescription-det">
                            <div class="prescription-subdet">medicineName</div>
                            <div class="prescription-subdet">medicineName</div>
                            <div class="prescription-subdet">medicineName</div>
                            <div class="prescription-subdet">medicineName</div>
                            <div class="prescription-subdet">medicineName</div>
                          </div>
                        </div>
                      </div>
                      ))}
                    </div> */}

                {/* <div class="table-responsive mb-3">
                            <table class="table table-border-main mb-0">
                                <tr style={{backgroundColor:"#cae5ff"}}>
                                    <th>18 jan 2021</th>
                                    <th colSpan="4">Doctor Name: Dr. Rahul Knot</th>
                                </tr>
                                <tr>
                                    <th>Medicine</th>
                                    <th>Frequency & Dosage</th>
                                    <th>Intake</th>
                                    <th>Qty</th>
                                    <th>Days</th>
                                </tr>
                                <tr>
                                    <td>Medicine
                                        <a data-tip data-for='prescription-view'> 
                                            <span class="fa fa-eye ml-3"></span>
                                            <ReactTooltip id='prescription-view' className='presctooltip' effect='solid' type="light">
                                                <div className="mb-1">
                                                    <i class="fa fa-caret-left mr-1"></i><span className="presc-span">Interactions</span>
                                                </div>
                                                <div className="mb-1 ml-2">Not suitable for diabetics</div>
                                                <div className="mb-1">
                                                    <i class="fa fa-caret-left mr-1"></i><span className="presc-span">Drug Reactions</span>
                                                </div>
                                                <div className="mb-1 ml-2">Check if diabetic</div>
                                                <div className="mb-1">
                                                    <i class="fa fa-caret-left mr-1"></i><span className="presc-span">Major Side Effects</span>
                                                </div>
                                                <div className="mb-1 ml-2">AAA</div>
                                                <div className="mb-1">
                                                    <i class="fa fa-caret-left mr-1"></i><span className="presc-span">Precautions</span>
                                                </div>
                                                <div className="mb-1 ml-2">Is patient diabetic</div>
                                            </ReactTooltip> 
                                        </a>
                                    </td>
                                    <td>Frequency & Dosage</td>
                                    <td>Intake</td>
                                    <td>Qty</td>
                                    <td>Days</td>
                                </tr>
                                <tr>
                                    <td>Medicine
                                        <a data-tip data-for='prescription-view'> 
                                            <span class="fa fa-eye ml-3"></span>
                                            <ReactTooltip id='prescription-view' className='presctooltip' effect='solid' type="light">
                                                <div className="mb-1">
                                                    <i class="fa fa-caret-left mr-1"></i><span className="presc-span">Interactions</span>
                                                </div>
                                                <div className="mb-1 ml-2">Not suitable for diabetics</div>
                                                <div className="mb-1">
                                                    <i class="fa fa-caret-left mr-1"></i><span className="presc-span">Drug Reactions</span>
                                                </div>
                                                <div className="mb-1 ml-2">Check if diabetic</div>
                                                <div className="mb-1">
                                                    <i class="fa fa-caret-left mr-1"></i><span className="presc-span">Major Side Effects</span>
                                                </div>
                                                <div className="mb-1 ml-2">AAA</div>
                                                <div className="mb-1">
                                                    <i class="fa fa-caret-left mr-1"></i><span className="presc-span">Precautions</span>
                                                </div>
                                                <div className="mb-1 ml-2">Is patient diabetic</div>
                                            </ReactTooltip> 
                                        </a>
                                    </td>
                                    <td>Frequency & Dosage</td>
                                    <td>Intake</td>
                                    <td>Qty</td>
                                    <td>Days</td>
                                </tr>
                            </table>
                        </div>  */}
              </div>
            </div>

            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
