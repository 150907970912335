import React, { Component, Suspense } from "react";
import $ from "jquery";
import AWS from "aws-sdk";
import { apiCalling } from "../../apiService";
import { uploadToS3 } from "../../UploadFile";

var s3;
var fileNameUploadPhoto;
const folderName = window.sessionStorage.getItem("homeName");
// const Header = React.lazy(() => import("../" + folderName + "/header"));
// const Footer = React.lazy(() => import("../" + folderName + "/footer"));


export default class DoctorPhoto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filePhoto: null,
      // s3bucketAccessKey: "",
      // s3bucketSecretKey: "",
      s3BucketMainFolder: "",
      s3BucketAddressPath: "",
      s3BucketDoctorImages: "",
      s3Bucket: "",
    }

  }

  componentDidMount() {

    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        "functionName": "getDropdownLists"

      })
    };

    apiCalling(apiJson).then((data) => {


      if (data.success === "1") {
        this.setState({
          // s3bucketAccessKey: data.result[0].s3bucketAccessKey,
          // s3bucketSecretKey: data.result[0].s3bucketSecretKey,
          s3BucketMainFolder: data.result[0].S3BucketMainFolder,
          s3BucketAddressPath: data.result[0].s3BucketAddressPath,
          s3BucketDoctorImages: data.result[0].S3BucketDoctorImages,
          s3Bucket: data.result[0].s3Bucket,
        })
      }
      if (this.props.doctorData[0] != undefined && this.props.doctorData[0].photoOnly !== "") {
        $('#photoDiv').show()
        $('#photo').attr('src', this.props.doctorData[0].photoWithPath);
        //$("#hidSIgnatureName").val(this.props.doctorData[0].signatureOnly)
      }
      // AWS.config.update({
      //   accessKeyId: data.result[0].s3bucketAccessKey,
      //   secretAccessKey: data.result[0].s3bucketSecretKey,
      //   region: "us-east-1"
      // });
      // var bucketParams = {
      //   Bucket: data.result[0].s3Bucket
      // };
      // s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });

    });


  }
  uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  handleImageUpload(e, mode) {

    if (typeof e.target.files[0] !== "undefined") {
      let file = e.target.files[0];
      let fileType = file['type'];
      //console.log('fileType........', fileType)
      let validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
      if (!validImageTypes.includes(fileType)) {
        alert("Invalid file type")
        $("#" + mode + "File").val(null)
        if (mode === 'attachPhoto') {
          $("#photo").attr('src', '');
          $("#photoDiv").hide()
        }

      }
      else {
        var fileObj = e.target.files[0]
        let reader = new FileReader();
        var img = new Image();
        img.src = window.URL.createObjectURL(fileObj);
        img.onload = function () {
          reader.onload = function (e) {
            if (mode === 'attachPhoto') {
              $("#photo").attr('src', e.target.result);
              $("#photoDiv").show()
            }

          };

          reader.readAsDataURL(fileObj);
        };
        if (mode === 'attachPhoto') {
          this.setState({ filePhoto: fileObj })
        }

      }
    }



  }

  async fileUpload(file, mode) {

    let reader = new FileReader();
    //let reader = new FileReader();
    // let s3BucketMainFolder1 = this.state.s3BucketMainFolder + "/" + this.state.s3BucketDoctorImages
    let fileName = this.uuidv4() + file.name;
    //  console.log('fileName.........' + fileName)
    // let fullFilePath = s3BucketMainFolder1 + "/" + fileName;
    fileNameUploadPhoto = fileName
    //  console.log('fullFilePath.........' + fullFilePath)
    if (mode === 'attachPhoto') {
      fileNameUploadPhoto = fileName
    }
    const s3url = await uploadToS3(file, this.state.s3BucketMainFolder + "/" + this.state.s3BucketDoctorImages, fileName, this.state.bucket)

    if (s3url) {
      return s3url
    }
    else {
      return null

    }
    // s3.upload(
    //   {
    //     Key: fullFilePath,
    //     Body: file,
    //     ACL: "public-read"
    //   },
    //   (err, data) => {

    //     if (err) {

    //       return null
    //     } else {


    //     }
    //   }
    // )
  }

  doctorPhoto() {
    var image = ''
    if (this.state.filePhoto !== null && this.props.doctorData.length > 0) {
      this.fileUpload(this.state.filePhoto, 'attachPhoto')
      image = fileNameUploadPhoto
    } else if (this.props.doctorData.length > 0 && this.props.doctorData[0].photoOnly !== "") {
      image = this.props.doctorData[0].photoOnly
    }
    return image;
  }
  render() {
    return (
      <div className="bg-clr-wthHead mb-4">
        <Suspense fallback={<div></div>}>
          <section>
            <div className="seoWrapperHspMangemnt">
              <div className="container doctr-wraper-border">
                <span className="seosectinTextHspMangemnt">Please save doctors details before you upload photos
                </span>

                <div className="mt-4 mb-5">
                  <div className="superadminDivBoxcover">
                    <div className="superadminDivBox superadminDivBoxwidtAuto">
                      <label> </label>
                      <div className="hspinfoimgaeuploadDiv">
                        <div className="hspinfo-btnupload">
                          <span for="attachPhoto">Upload</span>
                          <input type="file" id="attachPhoto" accept="image/*" name="attachPhoto" className="hspinfoInputfieldupload" onChange={(e) => this.handleImageUpload(e, "attachPhoto")} />
                        </div>
                        <div id="photoDiv" style={{ display: 'none' }}> <img height="30" width="35" id="photo"></img></div>

                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>


          </section>
        </Suspense>
        <Suspense fallback={<div></div>}>
        </Suspense>
      </div>
    );
  }
}
